* {
  font-family: 'Poppins', sans-serif;
}

@media screen and (max-width: 499px) {
  .back{  z-index: 100000;
    width: 50px;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;  
    cursor: pointer;

   }
 
  .blur{
    backdrop-filter: blur(5px);
    transform: translate(-50%, -50%);
    background-color: #00000094;
    position: fixed;
   width: 100%;
   height: 100vh;
   top: 50%;
   left: 50%;
   z-index: 100000;
   }
   
   .v2Quote{ text-align:center;
     color: white;
     font-weight: bold;
     border: 2px #facc15 solid;
     position: fixed;
     padding: 15px;
     font-size: 20px;
     top: 50%;
     left: 50%;
     z-index: 100000;
     transform: translate(-50%, -50%);
     text-align: center;
    width: 90%;
   }
 
  .v1{
    width: 50px;
    height: 50px;
    position: sticky;
    position: -webkit-sticky;
    bottom: 90%;
    left: 120%;
    margin-top: -70%;
    display: inline-block;
    z-index: 100000;
  }
  
  .stakeMainHome {
    display: flex;
    flex-flow: column nowrap;
    background-color: black;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    background: rgb(12,17,40);
    background: linear-gradient(99deg, rgba(12,17,40,1) 0%, rgba(0,0,0,1) 50%, rgba(10,33,23,1) 94%);
  }

  .homeBtn {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: space-between;
    width: 90%;
  }

  .homeBtn button {
    color: black;
    background-color: #facc15;
    width: 700px;
    padding: 20px;
    font-weight: bold;
    margin-left: 2%;
    margin-right: 2%;
    font-size: 15px;
   }

  .homeBtn button:hover{
    margin-bottom: 2%;
  }

  .homeImages {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 15%;
  }

  .homeImages1 {
    animation: Slide_Down1 6s ease infinite;
  }

  .homeImages2 {
    animation: Slide_Down2 5s ease infinite;
  }

  @keyframes Slide_Down1 {
    0% {
      transform: translateY(-5%);
      opacity: 1;
    }

    50% {
      transform: translateY(20%);
    }

    100% {
      transform: translateY(-5%);
    }

  }

  @keyframes Slide_Down2 {
    0% {
      transform: translateY(-5%);
      opacity: 1;
    }

    50% {
      transform: translateY(20%);
    }

    100% {
      transform: translateY(-5%);
    }

  }


  .right {
    width: 200px;
    height: 171px;
    margin-top: -30%;
  }

  .homeLogo {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }
  
  .triangleTopRight {
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 200px 200px 0;
    border-color: transparent #f3ba2f transparent transparent;
    position: sticky;
    position: -webkit-sticky;
    bottom: 80%;
    left: 90%;
    margin-top: -70%;
  }


  .stakeMain2 {
    border-right: 20px #f3ba2f solid;
    border-top: #f3ba2f 20px solid;

  }

  .sticky {
    width: 60%;

    bottom: 0;
    right: 90%;
    margin-top: -10%;
  }

  .logoNFT {
    width: 50%;
    transition: transform 1s;
    margin-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .submitBtn {
    background-color: #44a06c;
    color: white;
    font-size: 15px;
    padding: 5px;
    border-radius: 5px;
  }

  .buyDetails2 {
    border: 3px black solid;
    width: 80%;
    padding: 10px;
  }

  .emailSectionH {
    color: black;
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 10px;
    color: #facc15;
  }

  .emailSection {
    color: #facc15;
    background: rgba(255, 255, 255, 0.096);
    border: 2px dashed #facc15;
    margin-bottom: 5%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .emailSection2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 20px;
    flex-flow: column;
  }


  .prizeBtn {
    background-color: #facc15;
    color: black;
    width: fit-content;
    border: none;
    width: 110px;
    box-shadow: 0px 2px #a58503;
    font-size: 15px;

  }

  .prizeBtnDis {
    background-color: #8f8767;
    color: rgb(61, 61, 61);
    width: fit-content;
    border: none;
    width: 110px;
    box-shadow: 0px 2px #978e6b;
    cursor: no-drop;
    font-size: 15px;
  }

  .step {
    color: white;
    font-size: 15px;
  }

  .connect2Mob {
    display: none;
  }

  .tableMobile {
    display: none;
  }

  .number {
    border-radius: 10px;
    border: 2px rgb(59, 131, 240) solid;
    padding: 10px;
    width: 300px;
    text-align: center;
  }

  .formBtn {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .adminBtns button {
    width: 300px;
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: transform .3s;
    font-weight: 700;
    color: #ffffff;
    background-color: #115dce00;
    border-radius: 10px;
    border: 2px rgb(59, 131, 240) solid;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
  }

  .sentOrders {
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
    background-color: #3b3b3b00;
    width: 60%;
    padding: 10px;

  }

  .view2 {
    margin-left: 5%;
    width: 20%;
  }

  .or {
    color: white;
    font-weight: 500;
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .view {
    margin-right: 5%;
  }

  .adminBtnsMain {
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-top: 5%;
    border-radius: 5px;
    padding: 20px;
    width: 50%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid #d79f60;
  }

  .orders {
    margin-top: 1%;
  }



  .t1 {
    color: white;
    font-size: 20px;
    text-align: center;
    margin-top: 5%;
    text-decoration: underline;
    font-weight: 500;
  }

  .t2 {
    color: white;
    font-size: 20px;
    text-align: center;
    margin-top: 5%;
    text-decoration: underline;
    font-weight: 500;
  }

  table {
    color: white;
    border-collapse: collapse;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 2%;
    margin-bottom: 5%;
  }

  td,
  th {
    border: 1px solid #dddddd;
    padding: 8px;
    text-align: center;

  }

  .hideTable {
    display: none;
  }

  .adminBtns {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 70%;
    margin-left: auto;
    margin-right: auto;

  }



  .adminBtns button:hover {
    transform: scale(1.1);
  }

  .buyDetails {
    color: white;
    font-size: 25px;
    text-align: left;
    margin-left: 3%;
    margin-top: 5%;
  }

  .btnsMainMob {
    display: none;
  }

  .stakeWC {
    width: 180px;
    background-color: #facc15;
    border: none;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-weight: 700;
    transition: transform .3s;
    font-size: 17px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;

  }

  .stakeWCMain {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .home {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    cursor: pointer;
    transition: transform .3s;
  }

  .home:hover {
    transform: scale(1.1);
  }

  .stakeWC:hover {
    transform: scale(1.1);
  }

  .navBarStake {
    justify-content: flex-end;
    padding: 20px;
    margin-bottom: 2%;
  }

  .navBarStake2 {
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    display: flex;
    flex-flow: column nowrap;
  }

  .btns {
    z-index: 10000;
    width: 190px;
    background-color: #facc15;
    color: rgb(0, 0, 0);
    padding: 5px;
    font-weight: 500;
    margin-bottom: 7%;
    transition: transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    font-size: 20px;
  }

  .btnsDis {
    width: 190px;
    background-color: #77946c;
    border: none;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: transform .3s;
    cursor: no-drop;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .btns2 {
    width: 190px;
    color: rgb(0, 0, 0);
    padding: 5px;
    font-weight: 500;
    transition: transform .3s;
    background-color: rgb(238, 62, 62);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    border: none;
    font-size: 20px;
  }

  .btns2Dis {
    width: 190px;
    background-color: rgb(196, 134, 134);
    cursor: no-drop;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    transition: transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .btns:hover {
    transform: scale(1.1);
  }

  .btns2:hover {
    transform: scale(1.1);
  }

  .btnsMain {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    display: none;
  }

  .btnsMainBig {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 2%;
  }

  .markBar {
    background-color: #000000;
    margin-top: 2%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;

    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 10px;
  }

  .markBarCon {
    background-color: #ffffff;
    display: flex;
    flex-flow: row nowrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-around;
    margin-top: 2%;
    margin-bottom: 2%;
    padding: 10px;
  }

  .markBar2 {
    flex-flow: column nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: -10%;
  }

  .num {
    font-size: 23px;
    color: #000000;
    font-weight: bold;
    text-align: center;
  }

  .name {
    color: #000000;
    font-size: 20px;
    text-align: center;
    font-weight: 500;
  }

  .num2 {
    font-size: 18px;
    color: white;
    font-weight: bold;
    text-align: center;
  }

  .name2 {
    color: white;
    font-size: 15px;
    text-align: center;
    font-weight: 500;
  }

  .markBar img {
    width: 80px;
    height: 80px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 20%;
  }

  .markBarMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .stakeMain {
    display: flex;
    flex-flow: column nowrap;
    background-color: black;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .items {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .board {
    margin-top: 5%;
    margin-bottom: 5%;
    padding: 35px;
    border: #facc15 solid 5px;
    margin-left: 1%;
    margin-right: 1%;

    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .boardMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .nameStake {
    width: 50%;
  }

  .carrots {
    width: 90px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .egg {
    width: 70px;
    height: 70px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .egg2 {
    width: 95px;
    height: 95px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .boardImg {
    background-color: #ffb963;

  }

  .boardPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-bottom: 8%;
  }

  .approveBtns {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 2%;
  }

  .approveBtns1 {
    background-color: #facc15;
    color: rgb(0, 0, 0);
    padding: 15px;
    font-size: 20px;
    border: none;
    font-weight: bold;
    width: 250px;
    transition: transform .3s;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .approveBtns1:hover {
    transform: scale(1.1);
  }

  .approveBtns2:hover {
    transform: scale(1.1);
  }

  .approveBtns3:hover {
    transform: scale(1.1);
  }

  .approveBtns3 {
    background-color: rgb(231, 80, 80);
    color: white;
    padding: 15px;
    font-size: 20px;
    border: none;
    font-weight: bold;
    width: 250px;
    transition: transform .3s;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .approveBtns3Dis {
    background-color: rgb(224, 111, 111);
    border: none;
    cursor: no-drop;
    color: rgb(187, 183, 183);

    padding: 20px;
    font-size: 23px;
    font-weight: bold;
    width: 400px;
    transition: transform .3s;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .approveBtns2 {
    background: none;
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #70512b solid;
    font-weight: bold;
    width: 200px;
    transition: transform .3s;
  }

  .rabbitImg {
    width: 80px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
  }

  .rabbitImgLogo {
    width: 80px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    border: #facc15 2px solid;
    margin-bottom: 2%;

  }

  .rabbitImg2 {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .itemsDiv {
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 5%;
    width: 40%;
  }

  .itemImg {
    width: 400px;
    height: 213px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg2 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg3 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg4 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg5 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemName {
    color: white;
    font-weight: 500;
    text-align: center;
    padding-top: 2%;
    font-size: 20px;
    background-color: #000000;
  }

  .buyBtn {
    background-color: #000000;
    padding-top: 2%;
    padding-bottom: 3%;

  }

  .buy {
    background-color: #d79f60;
    color: rgb(0, 0, 0);
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 50px;
    transition: transform .2s;
    border: white 2px solid;
    font-weight: 700;
    transition: ease 0.5s;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 5%;
  }

  .buy:hover {
    background-color: rgb(0, 0, 0);
    color: #d79f60;
  }

  @-webkit-keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
    }

    100% {
      -webkit-transform: scale(1.0);
      opacity: 0;
    }
  }

  @keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    100% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
      opacity: 0;
    }
  }

  .logo2 {
    width: 20%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 20px;
    font-weight: 600;
    background-color: #ffffff;
    color: #487a17;
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border-radius: 10px;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 18px;
    font-weight: 500;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 50px;
    font-weight: bold;
    color: white;
  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding: 2px;
    padding-bottom: 20px;
    margin-right: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 10%;
  }


  .rmPic img {
    width: 100%;
    background-size: cover;
  }

  .rmMain {
    margin-top: 10%;
  }

  .unstakeImgM {
    display: none;
  }

  .formStake {
    z-index: 1;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .allWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    background-color: #000000;
  }

  .light {
    width: 100%;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.8267682072829132) 0%, rgba(0, 0, 0, 0.4009978991596639) 23%, rgba(0, 0, 0, 0.3253676470588235) 52%, rgba(0, 0, 0, 0.19371498599439774) 73%), url('./assets/bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    background-attachment: fixed;
  }

  .light2 {
    width: 100%;
    background-color: #000000;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }


  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
    z-index: 1;
    font-family: "Third Rail - Demo";
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #ff9900;
  }

  .in2 {
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    z-index: 1;
    justify-content: center;
  }

  .parrot {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: #32743b7a -15px 15px;
    border-radius: 20px;
  }


  @keyframes glowing {
    0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  .intro2 {
    color: rgb(255, 255, 255);
    font-size: 18px;
    padding-bottom: 10px;
    text-align: center;
    z-index: 1;
    font-weight: 500;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 10px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;

  }

  .successfully {
    margin-top: 1%;
    font-size: 20px;
    color: #000000;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;

  }

  .discord {
    padding-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    transition: transform .2s;
  }

  .discord:hover {
    transform: scale(1.1);
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  ::-webkit-scrollbar-thumb {
    background: #67c4b6;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #8fc7bf;
  }


  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 3%;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    color: white;
    font-weight: 600;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div:hover {
    color: rgb(250, 125, 52);
    cursor: pointer;
    transition: ease 0.5s;
  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 50px;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .mintbuttondiv {
    margin-top: 20px;
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) -5px 5px;
    cursor: pointer;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) 5px 5px;
    cursor: pointer;

  }


  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    font-size: 20px;
    margin-right: 20px;
    margin-left: 20px;
    padding: 10px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    box-shadow: rgba(94, 94, 94, 0.288) 0px 5px;
  }


  .btnfos-0-3:hover {

    color: rgb(78, 78, 78);
    cursor: pointer;

  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }

  .nftamount {
    color: #44a06c;
    font-size: 70px;
  }

  .walletConnect {
    display: flex;
    justify-content: center;
    margin-top: 10%;
    z-index: 1;

  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: rgb(4, 121, 4);
  }


  .headers {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 15px;
    margin-left: auto;
    margin-right: auto;

    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
  }

  .wallet2 {
    background-color: #d79f60;
    color: rgb(0, 0, 0);
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 8px;
    border-radius: 50px;
    transition: transform .2s;
    border: white 2px solid;
    font-weight: 700;
    transition: ease 0.5s;

  }

  .wallet2:hover {
    background-color: rgb(0, 0, 0);
    color: #d79f60;
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {
    z-index: 10000;
    background-color: black;
    border: solid 3px;
    color: rgb(255, 255, 255);
    padding: 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 17px;
    margin-left: auto;
    margin-right: auto;
    width: 250px;
    transition: transform .5s;
    z-index: 1;
    margin-bottom: 10%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    font-weight: bold;
  }

  .lookDown {
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  .wallet3::after {
    transition: all 0.8s
  }

  .totalSupply {
    text-align: center;
    font-size: 35px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    padding-bottom: 5%;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .footer {
    position: static;
    margin-bottom: 5%;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 20px;
    font-weight: 500;
  }


  .copyright2 {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 12px;
    padding-top: 6px;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .images_show {
    width: 90%;
  }

  .hide {
    display: none;
  }

  .stake {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: fit-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .stake2 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
  }

  .stake3 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
  }

  .stake:hover {}

  .hide:hover {}

  .tokenIds {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-bottom: 10%;
  }

  .tokenIds2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 55%;
  }

  .tS {
    color: #3a9ae8;
    text-align: center;
    margin-top: 2%;
    font-size: 25px;
  }

  .unstakeImg {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

  }

  .imagesAndBtn {
    justify-content: center;
  }

  .flip-card {
    background-color: transparent;
    width: 150px;
    perspective: 1000px;
    background-color: #e0706c;
    height: fit-content;

    margin-top: 10%;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .btnCenter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .flip-card-front {
    /* background-color: #bbb;*/
    color: black;
  }

  .flip-card-back {
    /*background-color: #2980b9;*/
    color: white;
    transform: rotateY(180deg);
  }

  .loginFormNote {
    height: 90%;
    width: 90%;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }

  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 80%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;

  }

  .formMain1 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;

    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
  }

  .ownNFT {
    margin-bottom: 55%;
  }

  .elementName {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
  }

  .elementName2 {
    font-weight: 500;
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .elementName3 {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .medalDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .closeNote {
    position: absolute;
    right: -5%;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  .rewards {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .discordB {
    display: none;
  }

  .medal {
    width: 35px;
    height: 35px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .stakebtn {
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: transform .3s;
    margin-left: 3%;
    margin-right: 3%;
    font-weight: 700;
    color: #ffffff;
    background-color: #ce5d11;
    border-radius: 50px;
    border: none;
    box-shadow: #b15411 0px 5px;
  }

  .stakebtn2 {
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: transform .3s;
    margin-left: 3%;
    margin-right: 3%;
    font-weight: 700;
    color: #ffffff;
    background-color: #ce5d11;
    border-radius: 50px;
    border: none;
    box-shadow: #b15411 0px 5px;
  }

  .stakebtn:hover {
    transform: scale(1.1);
  }

  .stakebtn2:hover {
    transform: scale(1.1);
  }

  .stakebtnDiv {
    z-index: 10000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .logoPic {
    width: 110px;
    border-radius: 100%;
    border: 2px solid #ffffff;
  }

  .logoPic2 {
    border-radius: 50%;
    width: 10%;
    border: 1px solid #39a7f1;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 15px #1e98e9;
  }

  .logo {
    justify-content: flex-start;
    margin-top: auto;
    margin-bottom: auto;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;

  }

  .btnT {
    font-size: 30px;
    text-align: center;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 2%;
    margin-top: 5%;
  }

  * {
    box-sizing: border-box;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: #e89d38;
    padding-left: 100%;
    margin-top: 5%;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 18px;
    color: #ffffff;
    font-weight: 800;
  }

  .boxWrapC {
    margin-top: 10%;
  }

  .mainTB {
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 50px;

  }

  .mainTBP {
    text-align: center;
    color: white;
    font-weight: 500;
    font-size: 20px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  .sections3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 5%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #64a81f;
    border-radius: 50px;
    border: 2px white solid;
    padding: 10px;
  }

  .sT {
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 1%;
  }

  .sT2 {
    text-align: center;
    color: white;
    font-weight: 500;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .footer2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 10%;
  }

  .footer2 img {
    margin-left: 10px;
    margin-right: 10px;
    width: 40px;
    cursor: pointer;
    transition: transform .3s;
  }

  .footer2 img:hover {
    transform: scale(1.1);
  }

  .tName {
    color: white;
    justify-content: flex-start;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    font-size: 35px;
    font-weight: bold;
    cursor: pointer;
  }

  .stakeT {
    color: #facc15;
    font-weight: bold;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 30px;
  }

  .winnerSection {
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .winnerT {
    text-align: center;
    color: #facc15;
    font-weight: bold;
    font-size: 40px;
    text-decoration: underline white;
  }

  table {
    border-collapse: collapse;
    width: 80%;
    font-size: 12px;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: center;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #1f1f1f;
    color: #facc15;
  }

  .bg {
    background-color: #facc15;
    color: black;
  }



  .imageSet {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .imageSet img {
    width: 30%;
    transition: transform 1s;
  }

  .imageSet img:hover {
    width: 30%;
    transform: scale(1.2);
  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {
  .back{  z-index: 100000;
    width: 50px;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;  
    cursor: pointer;

   }
 
  .blur{
    backdrop-filter: blur(5px);
    transform: translate(-50%, -50%);
    background-color: #00000094;
    position: fixed;
   width: 100%;
   height: 100vh;
   top: 50%;
   left: 50%;
   z-index: 100000;
   
   }
   
   .v2Quote{ text-align:center;
     color: white;
     font-weight: bold;
     border: 2px #facc15 solid;
     position: fixed;
     padding: 15px;
     font-size: 20px;
     text-align: center;
     top: 50%;
     left: 50%;
     z-index: 100000;
     transform: translate(-50%, -50%);
    width: 90%;
   }
  .v1{
    width: 50px;
    height: 50px;
    position: sticky;
    position: -webkit-sticky;
    bottom: 90%;
    left: 120%;
    margin-top: -70%;
    display: inline-block;
    z-index: 100000;
  }
  
  .stakeMainHome {
    display: flex;
    flex-flow: column nowrap;
    background-color: black;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    background: rgb(12,17,40);
    background: linear-gradient(99deg, rgba(12,17,40,1) 0%, rgba(0,0,0,1) 50%, rgba(10,33,23,1) 94%);
  }

  .homeBtn {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: space-between;
    width: 75%;
  }

  .homeBtn button {
    color: black;
    background-color: #facc15;
    width: 700px;
    padding: 20px;
    font-weight: bold;
    margin-left: 2%;
    margin-right: 2%;
   }

  .homeBtn button:hover{
    margin-bottom: 2%;
  }

  .homeImages {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 10%;
  }

  .homeImages1 {
    animation: Slide_Down1 6s ease infinite;
  }

  .homeImages2 {
    animation: Slide_Down2 5s ease infinite;
  }

  @keyframes Slide_Down1 {
    0% {
      transform: translateY(-5%);
      opacity: 1;
    }

    50% {
      transform: translateY(20%);
    }

    100% {
      transform: translateY(-5%);
    }

  }

  @keyframes Slide_Down2 {
    0% {
      transform: translateY(-5%);
      opacity: 1;
    }

    50% {
      transform: translateY(20%);
    }

    100% {
      transform: translateY(-5%);
    }

  }


  .right {
    width: 250px;
    height: 214px;
    margin-top: -30%;
  }

  .homeLogo {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;

  }
  
  .triangleTopRight {
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 200px 200px 0;
    border-color: transparent #f3ba2f transparent transparent;
    position: sticky;
    position: -webkit-sticky;
    bottom: 80%;
    left: 90%;
    margin-top: -70%;
  }


  .stakeMain2 {
    border-right: 20px #f3ba2f solid;
    border-top: #f3ba2f 20px solid;

  }

  .sticky {
    width: 50%;

    bottom: 0;
    margin-top: -10%;
  }


  .logoNFT {
    width: 50%;
    transition: transform 1s;
    margin-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .submitBtn {
    background-color: #44a06c;
    color: white;
    font-size: 18px;
    padding: 5px;
    border-radius: 5px;
  }

  .buyDetails2 {
    border: 3px black solid;
    width: 70%;
    padding: 10px;
  }

  .emailSectionH {
    color: black;
    font-weight: bold;
    font-size: 17px;
    margin-bottom: 10px;
    color: #facc15;
  }

  .emailSection {
    color: #facc15;
    background: rgba(255, 255, 255, 0.096);
    border: 2px dashed #facc15;
    margin-bottom: 5%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .emailSection2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 20px;
    flex-flow: column;
  }


  .prizeBtn {
    background-color: #facc15;
    color: black;
    width: fit-content;
    border: none;
    width: 110px;
    box-shadow: 0px 2px #a58503;
    font-size: 15px;

  }

  .prizeBtnDis {
    background-color: #8f8767;
    color: rgb(61, 61, 61);
    width: fit-content;
    border: none;
    width: 110px;
    box-shadow: 0px 2px #978e6b;
    cursor: no-drop;
    font-size: 15px;
  }

  .step {
    color: white;
    font-size: 15px;
  }

  .connect2Mob {
    display: none;
  }

  .tableMobile {
    display: none;
  }

  .number {
    border-radius: 10px;
    border: 2px rgb(59, 131, 240) solid;
    padding: 10px;
    width: 300px;
    text-align: center;
  }

  .formBtn {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .adminBtns button {
    width: 300px;
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: transform .3s;
    font-weight: 700;
    color: #ffffff;
    background-color: #115dce00;
    border-radius: 10px;
    border: 2px rgb(59, 131, 240) solid;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
  }

  .sentOrders {
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
    background-color: #3b3b3b00;
    width: 60%;
    padding: 10px;

  }

  .view2 {
    margin-left: 5%;
    width: 20%;
  }

  .or {
    color: white;
    font-weight: 500;
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .view {
    margin-right: 5%;
  }

  .adminBtnsMain {
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-top: 5%;
    border-radius: 5px;
    padding: 20px;
    width: 50%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid #d79f60;
  }

  .orders {
    margin-top: 1%;
  }



  .t1 {
    color: white;
    font-size: 20px;
    text-align: center;
    margin-top: 5%;
    text-decoration: underline;
    font-weight: 500;
  }

  .t2 {
    color: white;
    font-size: 20px;
    text-align: center;
    margin-top: 5%;
    text-decoration: underline;
    font-weight: 500;
  }

  table {
    color: white;
    border-collapse: collapse;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 2%;
    margin-bottom: 5%;
  }

  td,
  th {
    border: 1px solid #dddddd;
    padding: 8px;
    text-align: center;

  }

  .hideTable {
    display: none;
  }

  .adminBtns {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 70%;
    margin-left: auto;
    margin-right: auto;

  }



  .adminBtns button:hover {
    transform: scale(1.1);
  }

  .buyDetails {
    color: white;
    font-size: 25px;
    text-align: left;
    margin-left: 3%;
    margin-top: 5%;
  }

  .btnsMainMob {
    display: none;
  }

  .stakeWC {
    width: 200px;
    background-color: #facc15;
    border: none;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-weight: 700;
    transition: transform .3s;
    font-size: 18px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;

  }

  .stakeWCMain {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .home {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    cursor: pointer;
    transition: transform .3s;
  }

  .home:hover {
    transform: scale(1.1);
  }

  .stakeWC:hover {
    transform: scale(1.1);
  }

  .navBarStake {
    justify-content: flex-end;
    padding: 20px;
    margin-bottom: 2%;
  }

  .navBarStake2 {
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    display: flex;
    flex-flow: column nowrap;
  }

  .btns {
    z-index: 10000;
    width: 190px;
    background-color: #facc15;
    color: rgb(0, 0, 0);
    padding: 5px;
    font-weight: 500;
    margin-bottom: 7%;
    transition: transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    font-size: 20px;
  }

  .btnsDis {
    width: 190px;
    background-color: #77946c;
    border: none;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: transform .3s;
    cursor: no-drop;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .btns2 {
    width: 190px;
    color: rgb(0, 0, 0);
    padding: 5px;
    font-weight: 500;
    transition: transform .3s;
    background-color: rgb(238, 62, 62);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    border: none;
    font-size: 20px;
  }

  .btns2Dis {
    width: 190px;
    background-color: rgb(196, 134, 134);
    cursor: no-drop;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    transition: transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .btns:hover {
    transform: scale(1.1);
  }

  .btns2:hover {
    transform: scale(1.1);
  }

  .btnsMain {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    display: none;
  }

  .btnsMainBig {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 2%;
  }

  .markBar {
    background-color: #000000;
    margin-top: 2%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;

    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 10px;
  }

  .markBarCon {
    background-color: #ffffff;
    display: flex;
    flex-flow: row nowrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-around;
    margin-top: 2%;
    margin-bottom: 2%;
    padding: 10px;
  }

  .markBar2 {
    flex-flow: column nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: -10%;
  }

  .num {
    font-size: 23px;
    color: #000000;
    font-weight: bold;
    text-align: center;
  }

  .name {
    color: #000000;
    font-size: 20px;
    text-align: center;
    font-weight: 500;
  }

  .num2 {
    font-size: 23px;
    color: white;
    font-weight: bold;
    text-align: center;
  }

  .name2 {
    color: white;
    font-size: 20px;
    text-align: center;
    font-weight: 500;
  }

  .markBar img {
    width: 80px;
    height: 80px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 20%;
  }

  .markBarMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .stakeMain {
    display: flex;
    flex-flow: column nowrap;
    background-color: black;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .items {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .board {
    margin-top: 5%;
    margin-bottom: 5%;
    padding: 35px;
    border: #facc15 solid 5px;
    margin-left: 1%;
    margin-right: 1%;

    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .boardMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .nameStake {
    width: 50%;
  }

  .carrots {
    width: 90px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .egg {
    width: 70px;
    height: 70px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .egg2 {
    width: 95px;
    height: 95px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .boardImg {
    background-color: #ffb963;

  }

  .boardPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-bottom: 8%;
  }

  .approveBtns {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 2%;
  }

  .approveBtns1 {
    background-color: #facc15;
    color: rgb(0, 0, 0);
    padding: 15px;
    font-size: 20px;
    border: none;
    font-weight: bold;
    width: 300px;
    transition: transform .3s;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .approveBtns1:hover {
    transform: scale(1.1);
  }

  .approveBtns2:hover {
    transform: scale(1.1);
  }

  .approveBtns3:hover {
    transform: scale(1.1);
  }

  .approveBtns3 {
    background-color: rgb(231, 80, 80);
    color: white;
    padding: 15px;
    font-size: 20px;
    border: none;
    font-weight: bold;
    width: 300px;
    transition: transform .3s;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .approveBtns3Dis {
    background-color: rgb(224, 111, 111);
    border: none;
    cursor: no-drop;
    color: rgb(187, 183, 183);

    padding: 20px;
    font-size: 23px;
    font-weight: bold;
    width: 400px;
    transition: transform .3s;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .approveBtns2 {
    background: none;
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #70512b solid;
    font-weight: bold;
    width: 200px;
    transition: transform .3s;
  }

  .rabbitImg {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
  }

  .rabbitImgLogo {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    border: #facc15 2px solid;
    margin-bottom: 2%;

  }

  .rabbitImg2 {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .itemsDiv {
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 5%;
    width: 40%;
  }

  .itemImg {
    width: 400px;
    height: 213px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg2 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg3 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg4 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg5 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemName {
    color: white;
    font-weight: 500;
    text-align: center;
    padding-top: 2%;
    font-size: 20px;
    background-color: #000000;
  }

  .buyBtn {
    background-color: #000000;
    padding-top: 2%;
    padding-bottom: 3%;

  }

  .buy {
    background-color: #d79f60;
    color: rgb(0, 0, 0);
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 50px;
    transition: transform .2s;
    border: white 2px solid;
    font-weight: 700;
    transition: ease 0.5s;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 5%;
  }

  .buy:hover {
    background-color: rgb(0, 0, 0);
    color: #d79f60;
  }

  @-webkit-keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
    }

    100% {
      -webkit-transform: scale(1.0);
      opacity: 0;
    }
  }

  @keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    100% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
      opacity: 0;
    }
  }

  .logo2 {
    width: 20%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 20px;
    font-weight: 600;
    background-color: #ffffff;
    color: #487a17;
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border-radius: 10px;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 18px;
    font-weight: 500;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 50px;
    font-weight: bold;
    color: white;
  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding: 2px;
    padding-bottom: 20px;
    margin-right: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 10%;
  }


  .rmPic img {
    width: 100%;
    background-size: cover;
  }

  .rmMain {
    margin-top: 10%;
  }

  .unstakeImgM {
    display: none;
  }

  .formStake {
    z-index: 1;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .allWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    background-color: #000000;
  }

  .light {
    width: 100%;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.8267682072829132) 0%, rgba(0, 0, 0, 0.4009978991596639) 23%, rgba(0, 0, 0, 0.3253676470588235) 52%, rgba(0, 0, 0, 0.19371498599439774) 73%), url('./assets/bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    background-attachment: fixed;
  }

  .light2 {
    width: 100%;
    background-color: #000000;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }


  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
    z-index: 1;
    font-family: "Third Rail - Demo";
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #ff9900;
  }

  .in2 {
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    z-index: 1;
    justify-content: center;
  }

  .parrot {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: #32743b7a -15px 15px;
    border-radius: 20px;
  }


  @keyframes glowing {
    0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  .intro2 {
    color: rgb(255, 255, 255);
    font-size: 18px;
    padding-bottom: 10px;
    text-align: center;
    z-index: 1;
    font-weight: 500;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 10px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;

  }

  .successfully {
    margin-top: 1%;
    font-size: 20px;
    color: #000000;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;

  }

  .discord {
    padding-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    transition: transform .2s;
  }

  .discord:hover {
    transform: scale(1.1);
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  ::-webkit-scrollbar-thumb {
    background: #67c4b6;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #8fc7bf;
  }


  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 3%;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    color: white;
    font-weight: 600;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div:hover {
    color: rgb(250, 125, 52);
    cursor: pointer;
    transition: ease 0.5s;
  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 50px;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .mintbuttondiv {
    margin-top: 20px;
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) -5px 5px;
    cursor: pointer;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) 5px 5px;
    cursor: pointer;

  }


  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    font-size: 20px;
    margin-right: 20px;
    margin-left: 20px;
    padding: 10px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    box-shadow: rgba(94, 94, 94, 0.288) 0px 5px;
  }


  .btnfos-0-3:hover {

    color: rgb(78, 78, 78);
    cursor: pointer;

  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }

  .nftamount {
    color: #44a06c;
    font-size: 70px;
  }

  .walletConnect {
    display: flex;
    justify-content: center;
    margin-top: 10%;
    z-index: 1;

  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: rgb(4, 121, 4);
  }


  .headers {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 15px;
    margin-left: auto;
    margin-right: auto;

    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
  }

  .wallet2 {
    background-color: #d79f60;
    color: rgb(0, 0, 0);
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 8px;
    border-radius: 50px;
    transition: transform .2s;
    border: white 2px solid;
    font-weight: 700;
    transition: ease 0.5s;

  }

  .wallet2:hover {
    background-color: rgb(0, 0, 0);
    color: #d79f60;
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {
    z-index: 10000;
    background-color: black;
    border: solid 3px;
    color: rgb(255, 255, 255);
    padding: 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 270px;
    transition: transform .5s;
    z-index: 1;
    margin-bottom: 10%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    font-weight: bold;
  }

  .lookDown {
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  .wallet3::after {
    transition: all 0.8s
  }

  .totalSupply {
    text-align: center;
    font-size: 35px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    padding-bottom: 5%;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .footer {
    position: static;
    margin-bottom: 5%;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 20px;
    font-weight: 500;
  }


  .copyright2 {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 12px;
    padding-top: 6px;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .images_show {
    width: 90%;
  }

  .hide {
    display: none;
  }


  .stake3 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
  }

  .stake:hover {}

  .hide:hover {}

  .tokenIds {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-bottom: 10%;
  }

  .tokenIds2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40%;
  }

  .tS {
    color: #3a9ae8;
    text-align: center;
    margin-top: 2%;
    font-size: 40px;
    font-family: 'Comic Kings', sans-serif;
  }

  .unstakeImg {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

  }

  .imagesAndBtn {
    justify-content: center;
  }

  .flip-card {
    background-color: transparent;
    width: 150px;
    perspective: 1000px;
    background-color: #e0706c;
    height: fit-content;

    margin-top: 10%;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .btnCenter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .flip-card-front {
    /* background-color: #bbb;*/
    color: black;
  }

  .flip-card-back {
    /*background-color: #2980b9;*/
    color: white;
    transform: rotateY(180deg);
  }

  .loginFormNote {
    height: 90%;
    width: 90%;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }

  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 80%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;
  }

  .formMain1 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;

    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
  }

  .ownNFT {
    margin-bottom: 40%;
  }

  .elementName {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
  }

  .elementName2 {
    font-weight: 500;
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .elementName3 {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .medalDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  .rewards {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .discordB {
    display: none;
  }

  .medal {
    width: 35px;
    height: 35px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .stakebtn {
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: transform .3s;
    margin-left: 3%;
    margin-right: 3%;
    font-weight: 700;
    color: #ffffff;
    background-color: #ce5d11;
    border-radius: 50px;
    border: none;
    box-shadow: #b15411 0px 5px;
  }

  .stakebtn2 {
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: transform .3s;
    margin-left: 3%;
    margin-right: 3%;
    font-weight: 700;
    color: #ffffff;
    background-color: #ce5d11;
    border-radius: 50px;
    border: none;
    box-shadow: #b15411 0px 5px;
  }

  .stakebtn:hover {
    transform: scale(1.1);
  }

  .stakebtn2:hover {
    transform: scale(1.1);
  }

  .stakebtnDiv {
    z-index: 10000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .logoPic {
    width: 110px;
    border-radius: 100%;
    border: 2px solid #ffffff;
  }

  .logoPic2 {
    border-radius: 50%;
    width: 10%;
    border: 1px solid #39a7f1;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 15px #1e98e9;
  }

  .logo {
    justify-content: flex-start;
    margin-top: auto;
    margin-bottom: auto;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;

  }

  .btnT {
    font-size: 30px;
    text-align: center;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 2%;
    margin-top: 5%;
  }

  * {
    box-sizing: border-box;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: #e89d38;
    padding-left: 100%;
    margin-top: 5%;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 18px;
    color: #ffffff;
    font-weight: 800;
  }

  .boxWrapC {
    margin-top: 10%;
  }

  .mainTB {
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 50px;

  }

  .mainTBP {
    text-align: center;
    color: white;
    font-weight: 500;
    font-size: 20px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  .sections3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 5%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #64a81f;
    border-radius: 50px;
    border: 2px white solid;
    padding: 10px;
  }

  .sT {
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 1%;
  }

  .sT2 {
    text-align: center;
    color: white;
    font-weight: 500;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .footer2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 10%;
  }

  .footer2 img {
    margin-left: 10px;
    margin-right: 10px;
    width: 40px;
    cursor: pointer;
    transition: transform .3s;
  }

  .footer2 img:hover {
    transform: scale(1.1);
  }

  .tName {
    color: white;
    justify-content: flex-start;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    font-size: 40px;
    font-weight: bold;
    cursor: pointer;
  }

  .stakeT {
    color: #facc15;
    font-weight: bold;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 45px;
  }

  .winnerSection {
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .winnerT {
    text-align: center;
    color: #facc15;
    font-weight: bold;
    font-size: 50px;
    text-decoration: underline white;
  }

  table {
    border-collapse: collapse;
    width: 80%;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: center;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #1f1f1f;
    color: #facc15;
  }

  .bg {
    background-color: #facc15;
    color: black;
  }



  .imageSet {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .imageSet img {
    width: 30%;
    transition: transform 1s;
  }

  .imageSet img:hover {
    width: 30%;
    transform: scale(1.2);
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .back{  z-index: 100000;
    width: 50px;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;  
    cursor: pointer;

   }
  .blur{
    backdrop-filter: blur(5px);
    transform: translate(-50%, -50%);
    background-color: #00000094;
    position: fixed;
   width: 100%;
   height: 100vh;
   top: 50%;
   left: 50%;
   z-index: 100000;
   
   }
   
   .v2Quote{ text-align:center;
     color: white;
     font-weight: bold;
     border: 2px #facc15 solid;
     position: fixed;
     padding: 15px;
     font-size: 30px;
     top: 50%;
     left: 50%;
     z-index: 100000;
     transform: translate(-50%, -50%);
   
   }
  .v1{
    width: 50px;
    height: 50px;
    position: sticky;
    position: -webkit-sticky;
    bottom: 90%;
    left: 120%;
    margin-top: -70%;
    display: inline-block;
    z-index: 100000;
  }
 
  .stakeMainHome {
    display: flex;
    flex-flow: column nowrap;
    background-color: black;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    background: rgb(12,17,40);
    background: linear-gradient(99deg, rgba(12,17,40,1) 0%, rgba(0,0,0,1) 50%, rgba(10,33,23,1) 94%);
  }

  .homeBtn {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: space-between;
    width: 60%;
  }

  .homeBtn button {
    color: black;
    background-color: #facc15;
    width: 700px;
    padding: 20px;
    font-weight: bold;
    margin-left: 2%;
    margin-right: 2%;
   }

  .homeBtn button:hover{
    margin-bottom: 2%;
  }

  .homeImages {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 5%;
  }

  .homeImages1 {
    animation: Slide_Down1 6s ease infinite;
  }

  .homeImages2 {
    animation: Slide_Down2 5s ease infinite;
  }

  @keyframes Slide_Down1 {
    0% {
      transform: translateY(-5%);
      opacity: 1;
    }

    50% {
      transform: translateY(20%);
    }

    100% {
      transform: translateY(-5%);
    }

  }

  @keyframes Slide_Down2 {
    0% {
      transform: translateY(-5%);
      opacity: 1;
    }

    50% {
      transform: translateY(20%);
    }

    100% {
      transform: translateY(-5%);
    }

  }


  .right {
    width: 350px;
    height: 300px;
    margin-top: -30%;
  }

  .homeLogo {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;

  }
  .triangleTopRight {
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 200px 200px 0;
    border-color: transparent #f3ba2f transparent transparent;
    position: sticky;
    position: -webkit-sticky;
    bottom: 80%;
    left: 90%;
    margin-top: -70%;
  }


  .stakeMain2 {
    border-right: 20px #f3ba2f solid;
    border-top: #f3ba2f 20px solid;

  }

  .sticky {
    width: 40%;

    bottom: 0;
    right: 90%;
    margin-top: -10%;
  }

  .logoNFT {
    width: 40%;
    transition: transform 1s;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 5%;
  }

  .submitBtn {
    background-color: #44a06c;
    color: white;
    font-size: 18px;
    padding: 5px;
    border-radius: 5px;
  }

  .buyDetails2 {
    border: 3px black solid;
    width: 50%;
    padding: 10px;
  }

  .emailSectionH {
    color: black;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
    color: #facc15;
  }

  .emailSection {
    color: #facc15;
    background: rgba(255, 255, 255, 0.096);
    border: 2px dashed #facc15;
    margin-bottom: 5%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .emailSection2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 20px;
    flex-flow: column;
  }


  .prizeBtn {
    background-color: #facc15;
    color: black;
    width: fit-content;
    border: none;
    width: 150px;
    box-shadow: 0px 2px #a58503;
  }

  .prizeBtnDis {
    background-color: #8f8767;
    color: rgb(61, 61, 61);
    width: fit-content;
    border: none;
    width: 150px;
    box-shadow: 0px 2px #978e6b;

    cursor: no-drop;

  }

  .step {
    color: white;
    font-size: 15px;
  }

  .connect2Mob {
    display: none;
  }

  .tableMobile {
    display: none;
  }

  .number {
    border-radius: 10px;
    border: 2px rgb(59, 131, 240) solid;
    padding: 10px;
    width: 300px;
    text-align: center;
  }

  .formBtn {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .adminBtns button {
    width: 300px;
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: transform .3s;
    font-weight: 700;
    color: #ffffff;
    background-color: #115dce00;
    border-radius: 10px;
    border: 2px rgb(59, 131, 240) solid;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
  }

  .sentOrders {
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
    background-color: #3b3b3b00;
    width: 60%;
    padding: 10px;

  }

  .view2 {
    margin-left: 5%;
    width: 20%;
  }

  .or {
    color: white;
    font-weight: 500;
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .view {
    margin-right: 5%;
  }

  .adminBtnsMain {
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-top: 5%;
    border-radius: 5px;
    padding: 20px;
    width: 50%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid #d79f60;
  }

  .orders {
    margin-top: 1%;
  }



  .t1 {
    color: white;
    font-size: 20px;
    text-align: center;
    margin-top: 5%;
    text-decoration: underline;
    font-weight: 500;
  }

  .t2 {
    color: white;
    font-size: 20px;
    text-align: center;
    margin-top: 5%;
    text-decoration: underline;
    font-weight: 500;
  }

  table {
    color: white;
    border-collapse: collapse;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 2%;
    margin-bottom: 5%;
  }

  td,
  th {
    border: 1px solid #dddddd;
    padding: 8px;
    text-align: center;

  }

  .hideTable {
    display: none;
  }

  .adminBtns {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 70%;
    margin-left: auto;
    margin-right: auto;

  }



  .adminBtns button:hover {
    transform: scale(1.1);
  }

  .buyDetails {
    color: white;
    font-size: 25px;
    text-align: left;
    margin-left: 3%;
    margin-top: 5%;
  }

  .btnsMainMob {
    display: none;
  }

  .stakeWC {
    width: 250px;
    background-color: #facc15;
    border: none;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-weight: 700;
    transition: transform .3s;
    font-size: 22px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;

  }

  .stakeWCMain {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .home {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    cursor: pointer;
    transition: transform .3s;
  }

  .home:hover {
    transform: scale(1.1);
  }

  .stakeWC:hover {
    transform: scale(1.1);
  }

  .navBarStake {
    justify-content: flex-end;
    padding: 20px;
    margin-bottom: 2%;
  }

  .navBarStake2 {
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    display: flex;
  }

  .btns {
    z-index: 10000;
    width: 190px;
    background-color: #facc15;
    color: rgb(0, 0, 0);
    padding: 5px;
    font-weight: 500;
    margin-bottom: 7%;
    transition: transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    font-size: 25px;
  }

  .btnsDis {
    width: 190px;
    background-color: #77946c;
    border: none;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: transform .3s;
    cursor: no-drop;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .btns2 {
    width: 190px;
    color: rgb(0, 0, 0);
    padding: 5px;
    font-weight: 500;
    transition: transform .3s;
    background-color: rgb(238, 62, 62);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    border: none;
    font-size: 25px;
  }

  .btns2Dis {
    width: 190px;
    background-color: rgb(196, 134, 134);
    cursor: no-drop;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    transition: transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .btns:hover {
    transform: scale(1.1);
  }

  .btns2:hover {
    transform: scale(1.1);
  }

  .btnsMain {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    display: none;
  }

  .btnsMainBig {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 2%;
  }

  .markBar {
    background-color: #000000;
    margin-top: 2%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;

    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 10px;
  }

  .markBarCon {
    background-color: #ffffff;
    display: flex;
    flex-flow: row nowrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-around;
    margin-top: 2%;
    margin-bottom: 2%;
    padding: 10px;
  }

  .markBar2 {
    flex-flow: column nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: -10%;
  }

  .num {
    font-size: 23px;
    color: #000000;
    font-weight: bold;
    text-align: center;
  }

  .name {
    color: #000000;
    font-size: 20px;
    text-align: center;
    font-weight: 500;
  }

  .num2 {
    font-size: 23px;
    color: white;
    font-weight: bold;
    text-align: center;
  }

  .name2 {
    color: white;
    font-size: 20px;
    text-align: center;
    font-weight: 500;
  }

  .markBar img {
    width: 80px;
    height: 80px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 20%;
  }

  .markBarMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .stakeMain {
    display: flex;
    flex-flow: column nowrap;
    background-color: black;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .items {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .board {
    margin-top: 5%;
    margin-bottom: 5%;
    padding: 35px;
    border: #facc15 solid 5px;
    margin-left: 1%;
    margin-right: 1%;

    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .boardMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }

  .nameStake {
    width: 50%;
  }

  .carrots {
    width: 90px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .egg {
    width: 70px;
    height: 70px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .egg2 {
    width: 95px;
    height: 95px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .boardImg {
    background-color: #ffb963;

  }

  .boardPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-bottom: 8%;
  }

  .approveBtns {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 2%;
  }

  .approveBtns1 {
    background-color: #facc15;
    color: rgb(0, 0, 0);
    padding: 20px;
    font-size: 23px;
    border: none;
    font-weight: bold;
    width: 400px;
    transition: transform .3s;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .approveBtns1:hover {
    transform: scale(1.1);
  }

  .approveBtns2:hover {
    transform: scale(1.1);
  }

  .approveBtns3:hover {
    transform: scale(1.1);
  }

  .approveBtns3 {
    background-color: rgb(231, 80, 80);
    color: white;
    padding: 20px;
    font-size: 23px;
    border: none;
    font-weight: bold;
    width: 400px;
    transition: transform .3s;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .approveBtns3Dis {
    background-color: rgb(224, 111, 111);
    border: none;
    cursor: no-drop;
    color: rgb(187, 183, 183);

    padding: 20px;
    font-size: 23px;
    font-weight: bold;
    width: 400px;
    transition: transform .3s;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .approveBtns2 {
    background: none;
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #70512b solid;
    font-weight: bold;
    width: 200px;
    transition: transform .3s;
  }

  .rabbitImg {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
  }

  .rabbitImgLogo {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    border: #facc15 2px solid;
    margin-bottom: 2%;

  }

  .rabbitImg2 {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .itemsDiv {
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 5%;
    width: 40%;
  }

  .itemImg {
    width: 400px;
    height: 213px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg2 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg3 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg4 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg5 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemName {
    color: white;
    font-weight: 500;
    text-align: center;
    padding-top: 2%;
    font-size: 20px;
    background-color: #000000;
  }

  .buyBtn {
    background-color: #000000;
    padding-top: 2%;
    padding-bottom: 3%;

  }

  .buy {
    background-color: #d79f60;
    color: rgb(0, 0, 0);
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 50px;
    transition: transform .2s;
    border: white 2px solid;
    font-weight: 700;
    transition: ease 0.5s;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 5%;
  }

  .buy:hover {
    background-color: rgb(0, 0, 0);
    color: #d79f60;
  }

  @-webkit-keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
    }

    100% {
      -webkit-transform: scale(1.0);
      opacity: 0;
    }
  }

  @keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    100% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
      opacity: 0;
    }
  }

  .logo2 {
    width: 20%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 20px;
    font-weight: 600;
    background-color: #ffffff;
    color: #487a17;
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border-radius: 10px;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 18px;
    font-weight: 500;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 50px;
    font-weight: bold;
    color: white;
  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding: 2px;
    padding-bottom: 20px;
    margin-right: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 10%;
  }


  .rmPic img {
    width: 100%;
    background-size: cover;
  }

  .rmMain {
    margin-top: 10%;
  }

  .unstakeImgM {
    display: none;
  }

  .formStake {
    z-index: 1;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .allWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    background-color: #000000;
  }

  .light {
    width: 100%;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.8267682072829132) 0%, rgba(0, 0, 0, 0.4009978991596639) 23%, rgba(0, 0, 0, 0.3253676470588235) 52%, rgba(0, 0, 0, 0.19371498599439774) 73%), url('./assets/bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    background-attachment: fixed;
  }

  .light2 {
    width: 100%;
    background-color: #000000;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }


  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
    z-index: 1;
    font-family: "Third Rail - Demo";
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #ff9900;
  }

  .in2 {
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    z-index: 1;
    justify-content: center;
  }

  .parrot {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: #32743b7a -15px 15px;
    border-radius: 20px;
  }


  @keyframes glowing {
    0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  .intro2 {
    color: rgb(255, 255, 255);
    font-size: 18px;
    padding-bottom: 10px;
    text-align: center;
    z-index: 1;
    font-weight: 500;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 10px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;

  }

  .successfully {
    margin-top: 1%;
    font-size: 20px;
    color: #000000;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;

  }

  .discord {
    padding-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    transition: transform .2s;
  }

  .discord:hover {
    transform: scale(1.1);
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  ::-webkit-scrollbar-thumb {
    background: #67c4b6;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #8fc7bf;
  }


  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 3%;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    color: white;
    font-weight: 600;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div:hover {
    color: rgb(250, 125, 52);
    cursor: pointer;
    transition: ease 0.5s;
  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 50px;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .mintbuttondiv {
    margin-top: 20px;
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) -5px 5px;
    cursor: pointer;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) 5px 5px;
    cursor: pointer;

  }


  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    font-size: 20px;
    margin-right: 20px;
    margin-left: 20px;
    padding: 10px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    box-shadow: rgba(94, 94, 94, 0.288) 0px 5px;
  }


  .btnfos-0-3:hover {

    color: rgb(78, 78, 78);
    cursor: pointer;

  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }

  .nftamount {
    color: #44a06c;
    font-size: 70px;
  }

  .walletConnect {
    display: flex;
    justify-content: center;
    margin-top: 10%;
    z-index: 1;

  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: rgb(4, 121, 4);
  }


  .headers {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 15px;
    margin-left: auto;
    margin-right: auto;

    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
  }

  .wallet2 {
    background-color: #d79f60;
    color: rgb(0, 0, 0);
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 50px;
    transition: transform .2s;
    border: white 2px solid;
    font-weight: 700;
    transition: ease 0.5s;

  }

  .wallet2:hover {
    background-color: rgb(0, 0, 0);
    color: #d79f60;
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {
    z-index: 10000;
    background-color: black;
    border: solid 3px;
    color: rgb(255, 255, 255);
    padding: 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 270px;
    transition: transform .5s;
    z-index: 1;
    margin-bottom: 10%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    font-weight: bold;
  }

  .lookDown {
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  .wallet3::after {
    transition: all 0.8s
  }

  .totalSupply {
    text-align: center;
    font-size: 35px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    padding-bottom: 5%;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .footer {
    position: static;
    margin-bottom: 5%;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 20px;
    font-weight: 500;
  }


  .copyright2 {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 12px;
    padding-top: 6px;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .images_show {
    width: 90%;
  }

  .hide {
    display: none;
  }


  .stake {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: fit-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .stake2 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
  }

  .stake3 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
  }

  .stake:hover {}

  .hide:hover {}

  .tokenIds {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-bottom: 10%;
  }

  .tokenIds2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25%;
  }

  .tS {
    color: #3a9ae8;
    text-align: center;
    margin-top: 2%;
    font-size: 40px;
    font-family: 'Comic Kings', sans-serif;
  }

  .unstakeImg {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

  }

  .imagesAndBtn {
    justify-content: center;
  }

  .flip-card {
    background-color: transparent;
    width: 150px;
    perspective: 1000px;
    background-color: #e0706c;
    height: fit-content;

    margin-top: 10%;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .btnCenter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .flip-card-front {
    /* background-color: #bbb;*/
    color: black;
  }

  .flip-card-back {
    /*background-color: #2980b9;*/
    color: white;
    transform: rotateY(180deg);
  }

  .loginFormNote {
    height: 90%;
    width: 90%;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }

  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 80%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;
  }

  .formMain1 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;

    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
  }

  .ownNFT {
    margin-top: 18%;
    margin-bottom: 5.5%;
  }

  .elementName {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
  }

  .elementName2 {
    font-weight: 500;
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .elementName3 {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .medalDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  .rewards {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .discordB {
    display: none;
  }

  .medal {
    width: 35px;
    height: 35px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .stakebtn {
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: transform .3s;
    margin-left: 3%;
    margin-right: 3%;
    font-weight: 700;
    color: #ffffff;
    background-color: #ce5d11;
    border-radius: 50px;
    border: none;
    box-shadow: #b15411 0px 5px;
  }

  .stakebtn2 {
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: transform .3s;
    margin-left: 3%;
    margin-right: 3%;
    font-weight: 700;
    color: #ffffff;
    background-color: #ce5d11;
    border-radius: 50px;
    border: none;
    box-shadow: #b15411 0px 5px;
  }

  .stakebtn:hover {
    transform: scale(1.1);
  }

  .stakebtn2:hover {
    transform: scale(1.1);
  }

  .stakebtnDiv {
    z-index: 10000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .logoPic {
    width: 110px;
    border-radius: 100%;
    border: 2px solid #ffffff;
  }

  .logoPic2 {
    border-radius: 50%;
    width: 10%;
    border: 1px solid #39a7f1;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 15px #1e98e9;
  }

  .logo {
    justify-content: flex-start;
    margin-top: auto;
    margin-bottom: auto;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;

  }

  .btnT {
    font-size: 30px;
    text-align: center;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 2%;
    margin-top: 5%;
  }

  * {
    box-sizing: border-box;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: #e89d38;
    padding-left: 100%;
    margin-top: 5%;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 18px;
    color: #ffffff;
    font-weight: 800;
  }

  .boxWrapC {
    margin-top: 10%;
  }

  .mainTB {
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 50px;

  }

  .mainTBP {
    text-align: center;
    color: white;
    font-weight: 500;
    font-size: 20px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  .sections3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 5%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #64a81f;
    border-radius: 50px;
    border: 2px white solid;
    padding: 10px;
  }

  .sT {
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 1%;
  }

  .sT2 {
    text-align: center;
    color: white;
    font-weight: 500;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .footer2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 10%;
  }

  .footer2 img {
    margin-left: 10px;
    margin-right: 10px;
    width: 40px;
    cursor: pointer;
    transition: transform .3s;
  }

  .footer2 img:hover {
    transform: scale(1.1);
  }

  .tName {
    color: white;
    justify-content: flex-start;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    font-size: 50px;
    font-weight: bold;
    cursor: pointer;
  }

  .stakeT {
    color: #facc15;
    font-weight: bold;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 45px;
  }

  .winnerSection {
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .winnerT {
    text-align: center;
    color: #facc15;
    font-weight: bold;
    font-size: 50px;
    text-decoration: underline white;
  }

  table {
    border-collapse: collapse;
    width: 80%;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: center;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #1f1f1f;
    color: #facc15;
  }

  .bg {
    background-color: #facc15;
    color: black;
  }



  .imageSet {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .imageSet img {
    width: 30%;
    transition: transform 1s;
  }

  .imageSet img:hover {
    width: 30%;
    transform: scale(1.2);
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .back{  z-index: 100000;
    width: 50px;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;  
    cursor: pointer;

   }
  .blur{
    backdrop-filter: blur(5px);
    transform: translate(-50%, -50%);
    background-color: #00000094;
    position: fixed;
   width: 100%;
   height: 100vh;
   top: 50%;
   left: 50%;
   z-index: 100000;
   
   }
   
   .v2Quote{ text-align:center;
     color: white;
     font-weight: bold;
     border: 2px #facc15 solid;
     position: fixed;
     padding: 15px;
     font-size: 30px;
     top: 50%;
     left: 50%;
     z-index: 100000;
     transform: translate(-50%, -50%);
   
   }
  .v1{
    width: 50px;
    height: 50px;
    position: sticky;
    position: -webkit-sticky;
    bottom: 90%;
    left: 120%;
    margin-top: -70%;
    display: inline-block;
    z-index: 100000;
  }
 
  .stakeMainHome {
    display: flex;
    flex-flow: column nowrap;
    background-color: black;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    background: rgb(12,17,40);
    background: linear-gradient(99deg, rgba(12,17,40,1) 0%, rgba(0,0,0,1) 50%, rgba(10,33,23,1) 94%);
  }

  .homeBtn {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: space-between;
    width: 50%;
  }

  .homeBtn button {
    color: black;
    background-color: #facc15;
    width: 700px;
    padding: 20px;
    font-weight: bold;
    margin-left: 2%;
    margin-right: 2%;
   }

  .homeBtn button:hover{
    margin-bottom: 2%;
  }

  .homeImages {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-bottom: 2%;
  }

  .homeImages1 {
    animation: Slide_Down1 6s ease infinite;
  }

  .homeImages2 {
    animation: Slide_Down2 5s ease infinite;
  }

  @keyframes Slide_Down1 {
    0% {
      transform: translateY(-5%);
      opacity: 1;
    }

    50% {
      transform: translateY(20%);
    }

    100% {
      transform: translateY(-5%);
    }

  }

  @keyframes Slide_Down2 {
    0% {
      transform: translateY(-5%);
      opacity: 1;
    }

    50% {
      transform: translateY(20%);
    }

    100% {
      transform: translateY(-5%);
    }

  }


  .right {
    width: 350px;
    height: 300px;
    margin-top: -70%;
  }

  .homeLogo {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;

  }
  .triangleTopRight {
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 200px 200px 0;
    border-color: transparent #f3ba2f transparent transparent;
    position: sticky;
    position: -webkit-sticky;
    bottom: 80%;
    left: 90%;
    margin-top: -70%;
  }


  .stakeMain2 {
    border-right: 20px #f3ba2f solid;
    border-top: #f3ba2f 20px solid;

  }

  .sticky {
    width: 35%;

    bottom: 0;
    right: 90%;
    margin-top: -10%;
  }

  .logoNFT {
    width: 40%;
    transition: transform 1s;
  }

  .submitBtn {
    background-color: #44a06c;
    color: white;
    font-size: 18px;
    padding: 5px;
    border-radius: 5px;
  }

  .buyDetails2 {
    border: 3px black solid;
    width: 50%;
    padding: 10px;
  }

  .emailSectionH {
    color: black;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
    color: #facc15;
  }

  .emailSection {
    color: #facc15;
    background: rgba(255, 255, 255, 0.096);
    border: 2px dashed #facc15;
    margin-bottom: 5%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .emailSection2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 20px;
    flex-flow: column;
  }


  .prizeBtn {
    background-color: #facc15;
    color: black;
    width: fit-content;
    border: none;
    width: 150px;
    box-shadow: 0px 2px #a58503;
  }

  .prizeBtnDis {
    background-color: #8f8767;
    color: rgb(61, 61, 61);
    width: fit-content;
    border: none;
    width: 150px;
    box-shadow: 0px 2px #978e6b;
    cursor: no-drop;

  }

  .step {
    color: white;
    font-size: 15px;
  }

  .connect2Mob {
    display: none;
  }

  .tableMobile {
    display: none;
  }

  .number {
    border-radius: 10px;
    border: 2px rgb(59, 131, 240) solid;
    padding: 10px;
    width: 300px;
    text-align: center;
  }

  .formBtn {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .adminBtns button {
    width: 300px;
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: transform .3s;
    font-weight: 700;
    color: #ffffff;
    background-color: #115dce00;
    border-radius: 10px;
    border: 2px rgb(59, 131, 240) solid;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
  }

  .sentOrders {
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
    background-color: #3b3b3b00;
    width: 60%;
    padding: 10px;

  }

  .view2 {
    margin-left: 5%;
    width: 20%;
  }

  .or {
    color: white;
    font-weight: 500;
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .view {
    margin-right: 5%;
  }

  .adminBtnsMain {
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-top: 5%;
    border-radius: 5px;
    padding: 20px;
    width: 50%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid #d79f60;
  }

  .orders {
    margin-top: 1%;
  }



  .t1 {
    color: white;
    font-size: 20px;
    text-align: center;
    margin-top: 5%;
    text-decoration: underline;
    font-weight: 500;
  }

  .t2 {
    color: white;
    font-size: 20px;
    text-align: center;
    margin-top: 5%;
    text-decoration: underline;
    font-weight: 500;
  }

  table {
    color: white;
    border-collapse: collapse;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 2%;
    margin-bottom: 5%;
  }

  td,
  th {
    border: 1px solid #dddddd;
    padding: 8px;
    text-align: center;

  }

  .hideTable {
    display: none;
  }

  .adminBtns {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 70%;
    margin-left: auto;
    margin-right: auto;

  }



  .adminBtns button:hover {
    transform: scale(1.1);
  }

  .buyDetails {
    color: white;
    font-size: 25px;
    text-align: left;
    margin-left: 3%;
    margin-top: 5%;
  }

  .btnsMainMob {
    display: none;
  }

  .stakeWC {
    width: 250px;
    background-color: #facc15;
    border: none;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-weight: 700;
    transition: transform .3s;
    font-size: 22px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;

  }

  .stakeWCMain {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .home {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    cursor: pointer;
    transition: transform .3s;
  }

  .home:hover {
    transform: scale(1.1);
  }

  .stakeWC:hover {
    transform: scale(1.1);
  }

  .navBarStake {
    justify-content: flex-end;
    padding: 20px;
    margin-bottom: 2%;
  }

  .navBarStake2 {
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    display: flex;
  }

  .btns {
    z-index: 10000;
    width: 190px;
    background-color: #facc15;
    color: rgb(0, 0, 0);
    padding: 5px;
    font-weight: 500;
    margin-bottom: 7%;
    transition: transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    font-size: 25px;
  }

  .btnsDis {
    width: 190px;
    background-color: #77946c;
    border: none;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: transform .3s;
    cursor: no-drop;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .btns2 {
    width: 190px;
    color: rgb(0, 0, 0);
    padding: 5px;
    font-weight: 500;
    transition: transform .3s;
    background-color: rgb(238, 62, 62);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    border: none;
    font-size: 25px;
  }

  .btns2Dis {
    width: 190px;
    background-color: rgb(196, 134, 134);
    cursor: no-drop;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    transition: transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .btns:hover {
    transform: scale(1.1);
  }

  .btns2:hover {
    transform: scale(1.1);
  }

  .btnsMain {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    display: none;
  }

  .btnsMainBig {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 2%;
  }

  .markBar {
    background-color: #000000;
    margin-top: 2%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;

    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 10px;
  }

  .markBarCon {
    background-color: #ffffff;
    display: flex;
    flex-flow: row nowrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-around;
    margin-top: 2%;
    margin-bottom: 2%;
    padding: 10px;
  }

  .markBar2 {
    flex-flow: column nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: -10%;
  }

  .num {
    font-size: 23px;
    color: #000000;
    font-weight: bold;
    text-align: center;
  }

  .name {
    color: #000000;
    font-size: 20px;
    text-align: center;
    font-weight: 500;
  }

  .num2 {
    font-size: 23px;
    color: white;
    font-weight: bold;
    text-align: center;
  }

  .name2 {
    color: white;
    font-size: 20px;
    text-align: center;
    font-weight: 500;
  }

  .markBar img {
    width: 80px;
    height: 80px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 20%;
  }

  .markBarMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .stakeMain {
    display: flex;
    flex-flow: column nowrap;
    background-color: black;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .items {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .board {
    margin-top: 5%;
    margin-bottom: 5%;
    padding: 35px;
    border: #facc15 solid 5px;
    margin-left: 1%;
    margin-right: 1%;

    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .boardMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

  .nameStake {
    width: 50%;
  }

  .carrots {
    width: 90px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .egg {
    width: 70px;
    height: 70px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .egg2 {
    width: 95px;
    height: 95px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .boardImg {
    background-color: #ffb963;

  }

  .boardPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-bottom: 8%;
  }

  .approveBtns {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 2%;
  }

  .approveBtns1 {
    background-color: #facc15;
    color: rgb(0, 0, 0);
    padding: 20px;
    font-size: 23px;
    border: none;
    font-weight: bold;
    width: 400px;
    transition: transform .3s;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .approveBtns1:hover {
    transform: scale(1.1);
  }

  .approveBtns2:hover {
    transform: scale(1.1);
  }

  .approveBtns3:hover {
    transform: scale(1.1);
  }

  .approveBtns3 {
    background-color: rgb(231, 80, 80);
    color: white;
    padding: 20px;
    font-size: 23px;
    border: none;
    font-weight: bold;
    width: 400px;
    transition: transform .3s;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .approveBtns3Dis {
    background-color: rgb(224, 111, 111);
    border: none;
    cursor: no-drop;
    color: rgb(187, 183, 183);

    padding: 20px;
    font-size: 23px;
    font-weight: bold;
    width: 400px;
    transition: transform .3s;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .approveBtns2 {
    background: none;
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #70512b solid;
    font-weight: bold;
    width: 200px;
    transition: transform .3s;
  }

  .rabbitImg {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
  }

  .rabbitImgLogo {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    border: #facc15 2px solid;
    margin-bottom: 2%;

  }

  .rabbitImg2 {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .itemsDiv {
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 5%;
    width: 40%;
  }

  .itemImg {
    width: 400px;
    height: 213px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg2 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg3 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg4 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg5 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemName {
    color: white;
    font-weight: 500;
    text-align: center;
    padding-top: 2%;
    font-size: 20px;
    background-color: #000000;
  }

  .buyBtn {
    background-color: #000000;
    padding-top: 2%;
    padding-bottom: 3%;

  }

  .buy {
    background-color: #d79f60;
    color: rgb(0, 0, 0);
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 50px;
    transition: transform .2s;
    border: white 2px solid;
    font-weight: 700;
    transition: ease 0.5s;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 5%;
  }

  .buy:hover {
    background-color: rgb(0, 0, 0);
    color: #d79f60;
  }

  @-webkit-keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
    }

    100% {
      -webkit-transform: scale(1.0);
      opacity: 0;
    }
  }

  @keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    100% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
      opacity: 0;
    }
  }

  .logo2 {
    width: 20%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 20px;
    font-weight: 600;
    background-color: #ffffff;
    color: #487a17;
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border-radius: 10px;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 18px;
    font-weight: 500;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 50px;
    font-weight: bold;
    color: white;
  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding: 2px;
    padding-bottom: 20px;
    margin-right: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 10%;
  }


  .rmPic img {
    width: 100%;
    background-size: cover;
  }

  .rmMain {
    margin-top: 10%;
  }

  .unstakeImgM {
    display: none;
  }

  .formStake {
    z-index: 1;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .allWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    background-color: #000000;
  }

  .light {
    width: 100%;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.8267682072829132) 0%, rgba(0, 0, 0, 0.4009978991596639) 23%, rgba(0, 0, 0, 0.3253676470588235) 52%, rgba(0, 0, 0, 0.19371498599439774) 73%), url('./assets/bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    background-attachment: fixed;
  }

  .light2 {
    width: 100%;
    background-color: #000000;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }


  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
    z-index: 1;
    font-family: "Third Rail - Demo";
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #ff9900;
  }

  .in2 {
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    z-index: 1;
    justify-content: center;
  }

  .parrot {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: #32743b7a -15px 15px;
    border-radius: 20px;
  }


  @keyframes glowing {
    0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  .intro2 {
    color: rgb(255, 255, 255);
    font-size: 18px;
    padding-bottom: 10px;
    text-align: center;
    z-index: 1;
    font-weight: 500;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 10px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;

  }

  .successfully {
    margin-top: 1%;
    font-size: 20px;
    color: #000000;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;

  }

  .discord {
    padding-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    transition: transform .2s;
  }

  .discord:hover {
    transform: scale(1.1);
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  ::-webkit-scrollbar-thumb {
    background: #67c4b6;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #8fc7bf;
  }


  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 3%;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    color: white;
    font-weight: 600;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div:hover {
    color: rgb(250, 125, 52);
    cursor: pointer;
    transition: ease 0.5s;
  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 50px;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .mintbuttondiv {
    margin-top: 20px;
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) -5px 5px;
    cursor: pointer;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) 5px 5px;
    cursor: pointer;

  }


  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    font-size: 20px;
    margin-right: 20px;
    margin-left: 20px;
    padding: 10px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    box-shadow: rgba(94, 94, 94, 0.288) 0px 5px;
  }


  .btnfos-0-3:hover {

    color: rgb(78, 78, 78);
    cursor: pointer;

  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }

  .nftamount {
    color: #44a06c;
    font-size: 70px;
  }

  .walletConnect {
    display: flex;
    justify-content: center;
    margin-top: 10%;
    z-index: 1;

  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: rgb(4, 121, 4);
  }


  .headers {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 15px;
    margin-left: auto;
    margin-right: auto;

    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
  }

  .wallet2 {
    background-color: #d79f60;
    color: rgb(0, 0, 0);
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 50px;
    transition: transform .2s;
    border: white 2px solid;
    font-weight: 700;
    transition: ease 0.5s;

  }

  .wallet2:hover {
    background-color: rgb(0, 0, 0);
    color: #d79f60;
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {
    z-index: 10000;
    background-color: black;
    border: solid 3px;
    color: rgb(255, 255, 255);
    padding: 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 270px;
    transition: transform .5s;
    z-index: 1;
    margin-bottom: 10%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    font-weight: bold;
  }

  .lookDown {
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  .wallet3::after {
    transition: all 0.8s
  }

  .totalSupply {
    text-align: center;
    font-size: 35px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    padding-bottom: 5%;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .footer {
    position: static;
    margin-bottom: 5%;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 20px;
    font-weight: 500;
  }


  .copyright2 {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 12px;
    padding-top: 6px;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .images_show {
    width: 90%;
  }

  .hide {
    display: none;
  }

  .stake {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: fit-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .stake2 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
  }

  .stake3 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
  }

  .stake:hover {}

  .hide:hover {}

  .tokenIds {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-bottom: 10%;
  }

  .tokenIds2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20%;
  }

  .tS {
    color: #3a9ae8;
    text-align: center;
    margin-top: 2%;
    font-size: 40px;
    font-family: 'Comic Kings', sans-serif;
  }

  .unstakeImg {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

  }

  .imagesAndBtn {
    justify-content: center;
  }

  .flip-card {
    background-color: transparent;
    width: 150px;
    perspective: 1000px;
    background-color: #e0706c;
    height: fit-content;

    margin-top: 10%;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .btnCenter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .flip-card-front {
    /* background-color: #bbb;*/
    color: black;
  }

  .flip-card-back {
    /*background-color: #2980b9;*/
    color: white;
    transform: rotateY(180deg);
  }

  .loginFormNote {
    height: 90%;
    width: 90%;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }

  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 80%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;

  }

  .formMain1 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;

    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
  }

  .ownNFT {
    margin-top: 13%;
    margin-bottom: 5.5%;
  }

  .elementName {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
  }

  .elementName2 {
    font-weight: 500;
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .elementName3 {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .medalDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  .rewards {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .discordB {
    display: none;
  }

  .medal {
    width: 35px;
    height: 35px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .stakebtn {
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: transform .3s;
    margin-left: 3%;
    margin-right: 3%;
    font-weight: 700;
    color: #ffffff;
    background-color: #ce5d11;
    border-radius: 50px;
    border: none;
    box-shadow: #b15411 0px 5px;
  }

  .stakebtn2 {
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: transform .3s;
    margin-left: 3%;
    margin-right: 3%;
    font-weight: 700;
    color: #ffffff;
    background-color: #ce5d11;
    border-radius: 50px;
    border: none;
    box-shadow: #b15411 0px 5px;
  }

  .stakebtn:hover {
    transform: scale(1.1);
  }

  .stakebtn2:hover {
    transform: scale(1.1);
  }

  .stakebtnDiv {
    z-index: 10000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .logoPic {
    width: 110px;
    border-radius: 100%;
    border: 2px solid #ffffff;
  }

  .logoPic2 {
    border-radius: 50%;
    width: 10%;
    border: 1px solid #39a7f1;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 15px #1e98e9;
  }

  .logo {
    justify-content: flex-start;
    margin-top: auto;
    margin-bottom: auto;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;

  }

  .btnT {
    font-size: 30px;
    text-align: center;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 2%;
    margin-top: 5%;
  }

  * {
    box-sizing: border-box;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: #e89d38;
    padding-left: 100%;
    margin-top: 5%;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 18px;
    color: #ffffff;
    font-weight: 800;
  }

  .boxWrapC {
    margin-top: 10%;
  }

  .mainTB {
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 50px;

  }

  .mainTBP {
    text-align: center;
    color: white;
    font-weight: 500;
    font-size: 20px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  .sections3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 5%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #64a81f;
    border-radius: 50px;
    border: 2px white solid;
    padding: 10px;
  }

  .sT {
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 1%;
  }

  .sT2 {
    text-align: center;
    color: white;
    font-weight: 500;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .footer2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 10%;
  }

  .footer2 img {
    margin-left: 10px;
    margin-right: 10px;
    width: 40px;
    cursor: pointer;
    transition: transform .3s;
  }

  .footer2 img:hover {
    transform: scale(1.1);
  }

  .tName {
    color: white;
    justify-content: flex-start;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    font-size: 50px;
    font-weight: bold;
    cursor: pointer;
  }

  .stakeT {
    color: #facc15;
    font-weight: bold;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 45px;
  }

  .winnerSection {
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .winnerT {
    text-align: center;
    color: #facc15;
    font-weight: bold;
    font-size: 50px;
    text-decoration: underline white;
  }

  table {
    border-collapse: collapse;
    width: 80%;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: center;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #1f1f1f;
    color: #facc15;
  }

  .bg {
    background-color: #facc15;
    color: black;
  }



  .imageSet {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .imageSet img {
    width: 30%;
    transition: transform 1s;
  }

  .imageSet img:hover {
    width: 30%;
    transform: scale(1.2);
  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {

 .back{ 
  width: 50px;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;  
  cursor: pointer;
  z-index: 100000000;
 }

 .backDiv{
  z-index: 1000000000000000000;
 }

  .blur{
 backdrop-filter: blur(5px);
 transform: translate(-50%, -50%);
 background-color: #00000094;
 position: fixed;
width: 100%;
height: 100vh;
top: 50%;
left: 50%;
z-index: 100000;

}

.v2Quote{ text-align:center;
  color: white;
  font-weight: bold;
  border: 2px #facc15 solid;
  position: fixed;
  padding: 15px;
  font-size: 30px;
  top: 50%;
  left: 50%;
  z-index: 100000;
  transform: translate(-50%, -50%);
  
}

  .stakeMainHome {
    display: flex;
    flex-flow: column nowrap;
    background-color: black;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    background: rgb(12,17,40);
    background: linear-gradient(99deg, rgba(12,17,40,1) 0%, rgba(0,0,0,1) 50%, rgba(10,33,23,1) 94%);
  }

  .homeBtn {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: space-between;
    width: 30%;
  }

  .homeBtn button {
    color: black;
    background-color: #facc15;
    width: 200px;
    padding: 20px;
    font-weight: bold;
   }

  .homeBtn button:hover{
    margin-bottom: 2%;
  }

  .homeImages {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-bottom: 2%;
  }

  .homeImages1 {
    animation: Slide_Down1 6s ease infinite;
  }

  .homeImages2 {
    animation: Slide_Down2 5s ease infinite;
  }

  @keyframes Slide_Down1 {
    0% {
      transform: translateY(-5%);
      opacity: 1;
    }

    50% {
      transform: translateY(20%);
    }

    100% {
      transform: translateY(-5%);
    }

  }

  @keyframes Slide_Down2 {
    0% {
      transform: translateY(-5%);
      opacity: 1;
    }

    50% {
      transform: translateY(20%);
    }

    100% {
      transform: translateY(-5%);
    }

  }


  .right {
    width: 20%;
    margin-top: -70%;
  }

  .homeLogo {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;

  }

  .submitBtn {
    background-color: #44a06c;
    color: white;
    font-size: 18px;
    padding: 5px;
    border-radius: 5px;
  }

  .buyDetails2 {
    border: 3px black solid;
    width: 50%;
    padding: 10px;
  }

  .emailSectionH {
    color: black;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
    color: #facc15;
  }

  .emailSection {
    color: #facc15;
    background: rgba(255, 255, 255, 0.096);
    border: 2px dashed #facc15;
    margin-bottom: 5%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .emailSection2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 20px;
    flex-flow: column;
  }

  .prizeBtn {
    background-color: #facc15;
    color: black;
    width: fit-content;
    border: none;
    width: 150px;
    box-shadow: 0px 2px #a58503;
  }

  .prizeBtnDis {
    background-color: #8f8767;
    color: rgb(61, 61, 61);
    border: none;
    width: 150px;
    box-shadow: 0px 2px #978e6b;
    cursor: no-drop;
  }

  .step {
    color: white;
    font-size: 15px;
  }

  .connect2Mob {
    display: none;
  }

  .tableMobile {
    display: none;
  }

  .number {
    border-radius: 10px;
    border: 2px rgb(59, 131, 240) solid;
    padding: 10px;
    width: 300px;
    text-align: center;
  }

  .formBtn {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .adminBtns button {
    width: 300px;
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: transform .3s;
    font-weight: 700;
    color: #ffffff;
    background-color: #115dce00;
    border-radius: 10px;
    border: 2px rgb(59, 131, 240) solid;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
  }

  .sentOrders {
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
    background-color: #3b3b3b00;
    width: 60%;
    padding: 10px;

  }

  .view2 {
    margin-left: 5%;
    width: 20%;
  }

  .or {
    color: white;
    font-weight: 500;
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .view {
    margin-right: 5%;
  }

  .adminBtnsMain {
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-top: 5%;
    border-radius: 5px;
    padding: 20px;
    width: 50%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid #d79f60;
  }

  .orders {
    margin-top: 1%;
  }



  .t1 {
    color: white;
    font-size: 20px;
    text-align: center;
    margin-top: 5%;
    text-decoration: underline;
    font-weight: 500;
  }

  .t2 {
    color: white;
    font-size: 20px;
    text-align: center;
    margin-top: 5%;
    text-decoration: underline;
    font-weight: 500;
  }

  table {
    color: white;
    border-collapse: collapse;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 2%;
    margin-bottom: 5%;
  }

  td,
  th {
    border: 1px solid #dddddd;
    padding: 8px;
    text-align: center;

  }

  .hideTable {
    display: none;
  }

  .adminBtns {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 70%;
    margin-left: auto;
    margin-right: auto;

  }



  .adminBtns button:hover {
    transform: scale(1.1);
  }




  .buyDetails {
    color: white;
    font-size: 25px;
    text-align: left;
    margin-left: 3%;
    margin-top: 5%;
  }

  .btnsMainMob {
    display: none;
  }

  .stakeWC {
    width: 250px;
    background-color: #facc15;
    border: none;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-weight: 700;
    transition: transform .3s;
    font-size: 22px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    z-index: 10000;
  }

  .stakeWCMain {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .home {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    cursor: pointer;
    transition: transform .3s;
  }

  .home:hover {
    transform: scale(1.1);
  }

  .stakeWC:hover {
    transform: scale(1.1);
  }

  .navBarStake {
    justify-content: flex-end;
    padding: 20px;
    margin-bottom: 2%;

  }

  .navBarStake2 {
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    display: flex;

  }

  .triangleTopRight {
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 200px 200px 0;
    border-color: transparent #f3ba2f transparent transparent;
    position: sticky;
    position: -webkit-sticky;
    bottom: 80%;
    left: 90%;
    margin-top: -70%;
  }

  .v1{
    width: 50px;
    height: 50px;
    position: sticky;
    position: -webkit-sticky;
    bottom: 90%;
    left: 120%;
    margin-top: -70%;
    display: inline-block;
    z-index: 100000;
  }


  .btns {
    z-index: 10000;
    width: 150px;
    background-color: #facc15;
    color: rgb(0, 0, 0);
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;

  }

  .btnsDis {
    width: 150px;
    background-color: #77946c;
    border: none;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: transform .3s;
    cursor: no-drop;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .btns2 {
    width: 150px;
    color: rgb(0, 0, 0);
    padding: 5px;
    font-weight: 500;
    transition: transform .3s;
    background-color: rgb(238, 62, 62);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    border: none;
  }

  .btns2Dis {
    width: 150px;
    background-color: rgb(196, 134, 134);
    cursor: no-drop;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    transition: transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .btns:hover {
    transform: scale(1.1);
  }

  .btns2:hover {
    transform: scale(1.1);
  }

  .btnsMain {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    display: none;
  }

  .btnsMainBig {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .markBar {
    background-color: #000000;
    margin-top: 2%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    border: #facc15 3px solid;
    width: 90%;
    margin-left: auto;
    margin-right: auto;

    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 10px;
  }

  .markBarCon {
    background-color: #ffffff;
    display: flex;
    flex-flow: row nowrap;
    width: 20%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;

  }

  .markBar2 {
    flex-flow: column nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: -10%;
  }

  .num {
    font-size: 18px;
    color: #000000;
    font-weight: bold;
    text-align: center;
  }

  .name {
    color: #000000;
    font-size: 18px;
    text-align: center;
    font-weight: 500;
  }

  .num2 {
    font-size: 23px;
    color: white;
    font-weight: bold;
    text-align: center;
  }

  .name2 {
    color: white;
    font-size: 20px;
    text-align: center;
    font-weight: 500;
  }

  .markBar img {
    width: 70px;
    height: 70px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 20%;
  }

  .markBarMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .stakeMain {
    display: flex;
    flex-flow: column nowrap;
    background-color: black;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;

  }
 
  .stakeMain2 {
    border-right: 20px #f3ba2f solid;
    border-top: #f3ba2f 20px solid;
    
  }

  .sticky {
    width: 20%;

    bottom: 0;
    right: 90%;
    margin-top: -10%;
  }

  .items {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .board {
    margin-top: 5%;
    margin-bottom: 5%;
    padding: 35px;
    border: #facc15 solid 5px;
    margin-left: 1%;
    margin-right: 1%;

    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .boardMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }

  .nameStake {
    width: 50%;
  }

  .carrots {
    width: 90px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .egg {
    width: 70px;
    height: 70px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .egg2 {
    width: 95px;
    height: 95px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .boardImg {
    background-color: #ffb963;

  }

  .boardPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-bottom: 8%;
  }

  .approveBtns {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 2%;
  }

  .approveBtns1 {
    background-color: #facc15;
    color: rgb(0, 0, 0);
    padding: 20px;
    font-size: 23px;
    border: none;
    font-weight: bold;
    width: 400px;
    transition: transform .3s;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .approveBtns1:hover {
    transform: scale(1.1);
  }

  .approveBtns2:hover {
    transform: scale(1.1);
  }

  .approveBtns3:hover {
    transform: scale(1.1);
  }

  .approveBtns3 {
    background-color: rgb(231, 80, 80);
    color: white;
    padding: 20px;
    font-size: 23px;
    border: none;
    font-weight: bold;
    width: 400px;
    transition: transform .3s;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .approveBtns3Dis {
    background-color: rgb(224, 111, 111);
    border: none;
    cursor: no-drop;
    color: rgb(187, 183, 183);

    padding: 20px;
    font-size: 23px;
    font-weight: bold;
    width: 400px;
    transition: transform .3s;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .approveBtns2 {
    background: none;
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #70512b solid;
    font-weight: bold;
    width: 200px;
    transition: transform .3s;
  }

  .rabbitImg {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
  }

  .rabbitImgLogo {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    border: #facc15 2px solid;
    margin-bottom: 2%;

  }

  .rabbitImg2 {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .itemsDiv {
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 5%;
    width: 40%;
  }

  .itemImg {
    width: 400px;
    height: 213px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg2 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg3 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg4 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg5 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemName {
    color: white;
    font-weight: 500;
    text-align: center;
    padding-top: 2%;
    font-size: 20px;
    background-color: #000000;
  }

  .buyBtn {
    background-color: #000000;
    padding-top: 2%;
    padding-bottom: 3%;

  }

  .buy {
    background-color: #d79f60;
    color: rgb(0, 0, 0);
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 50px;
    transition: transform .2s;
    border: white 2px solid;
    font-weight: 700;
    transition: ease 0.5s;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 5%;
  }

  .buy:hover {
    background-color: rgb(0, 0, 0);
    color: #d79f60;
  }

  @-webkit-keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
    }

    100% {
      -webkit-transform: scale(1.0);
      opacity: 0;
    }
  }

  @keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    100% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
      opacity: 0;
    }
  }

  .logo2 {
    width: 20%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 20px;
    font-weight: 600;
    background-color: #ffffff;
    color: #487a17;
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border-radius: 10px;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 18px;
    font-weight: 500;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 50px;
    font-weight: bold;
    color: white;
  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding: 2px;
    padding-bottom: 20px;
    margin-right: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 10%;
  }


  .rmPic img {
    width: 100%;
    background-size: cover;
  }

  .rmMain {
    margin-top: 10%;
  }

  .unstakeImgM {
    display: none;
  }

  .formStake {
    z-index: 1;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .allWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    background-color: #000000;
  }

  .light {
    width: 100%;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.8267682072829132) 0%, rgba(0, 0, 0, 0.4009978991596639) 23%, rgba(0, 0, 0, 0.3253676470588235) 52%, rgba(0, 0, 0, 0.19371498599439774) 73%), url('./assets/bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    background-attachment: fixed;
  }

  .light2 {
    width: 100%;
    background-color: #000000;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }


  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
    z-index: 1;
    font-family: "Third Rail - Demo";
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #ff9900;
  }

  .in2 {
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    z-index: 1;
    justify-content: center;
  }

  .parrot {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: #32743b7a -15px 15px;
    border-radius: 20px;
  }


  @keyframes glowing {
    0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  .intro2 {
    color: rgb(255, 255, 255);
    font-size: 18px;
    padding-bottom: 10px;
    text-align: center;
    z-index: 1;
    font-weight: 500;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 10px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;

  }

  .successfully {
    margin-top: 1%;
    font-size: 20px;
    color: #000000;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;

  }

  .discord {
    padding-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    transition: transform .2s;
  }

  .discord:hover {
    transform: scale(1.1);
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  ::-webkit-scrollbar-thumb {
    background: #67c4b6;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #8fc7bf;
  }


  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
  /*color: #ffffffa2;
}*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 3%;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    color: white;
    font-weight: 600;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div:hover {
    color: rgb(250, 125, 52);
    cursor: pointer;
    transition: ease 0.5s;
  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 50px;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .mintbuttondiv {
    margin-top: 20px;
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) -5px 5px;
    cursor: pointer;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) 5px 5px;
    cursor: pointer;

  }


  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    font-size: 20px;
    margin-right: 20px;
    margin-left: 20px;
    padding: 10px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    box-shadow: rgba(94, 94, 94, 0.288) 0px 5px;
  }


  .btnfos-0-3:hover {

    color: rgb(78, 78, 78);
    cursor: pointer;

  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }

  .nftamount {
    color: #44a06c;
    font-size: 70px;
  }

  .walletConnect {
    display: flex;
    justify-content: center;
    margin-top: 10%;
    z-index: 1;

  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: rgb(4, 121, 4);
  }


  .headers {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 15px;
    margin-left: auto;
    margin-right: auto;

    /* border-left: #2d363600;
  border-right: #2d363600;*/
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;

  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
  }

  .wallet2 {
    background-color: #d79f60;
    color: rgb(0, 0, 0);
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 50px;
    transition: transform .2s;
    border: white 2px solid;
    font-weight: 700;
    transition: ease 0.5s;

  }

  .wallet2:hover {
    background-color: rgb(0, 0, 0);
    color: #d79f60;
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {
    z-index: 10000;
    background-color: black;
    border: solid 3px;
    color: rgb(255, 255, 255);
    padding: 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 270px;
    transition: transform .5s;
    z-index: 1;
    margin-bottom: 10%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    font-weight: bold;
  }

  .lookDown {
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  .wallet3::after {
    transition: all 0.8s
  }

  .totalSupply {
    text-align: center;
    font-size: 35px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    padding-bottom: 5%;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .footer {
    position: static;
    margin-bottom: 5%;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 20px;
    font-weight: 500;
  }


  .copyright2 {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 12px;
    padding-top: 6px;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .images_show {
    width: 90%;
  }

  .hide {
    display: none;
  }

  .stake {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: fit-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .stake2 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
  }

  .stake3 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
  }

  .stake:hover {}

  .hide:hover {}

  .tokenIds {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-bottom: 10%;
  }

  .tokenIds2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15%;
  }

  .tS {
    color: #3a9ae8;
    text-align: center;
    margin-top: 2%;
    font-size: 40px;
    font-family: 'Comic Kings', sans-serif;
  }

  .unstakeImg {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

  }

  .imagesAndBtn {
    justify-content: center;
  }

  .flip-card {
    background-color: transparent;
    width: 150px;
    perspective: 1000px;
    background-color: #e0706c;
    height: fit-content;

    margin-top: 10%;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .btnCenter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .flip-card-front {
    /* background-color: #bbb;*/
    color: black;
  }

  .flip-card-back {
    /*background-color: #2980b9;*/
    color: white;
    transform: rotateY(180deg);
  }

  .loginFormNote {
    height: 90%;
    width: 90%;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }

  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 80%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;

  }

  .formMain2 {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 50%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;
    padding: 10px;
  }

  .formMain1 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
  }

  .formMain1-1 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    background-color: #00000062;
  }

  .ownNFT {
    margin-top: 8%;
    margin-bottom: 5.5%;
  }

  .elementName {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
  }

  .elementName2 {
    font-weight: 500;
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .elementName3 {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .medalDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  .rewards {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .discordB {
    display: none;
  }

  .medal {
    width: 35px;
    height: 35px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .stakebtn {
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: transform .3s;
    margin-left: 3%;
    margin-right: 3%;
    font-weight: 700;
    color: #ffffff;
    background-color: #ce5d11;
    border-radius: 50px;
    border: none;
    box-shadow: #b15411 0px 5px;
  }

  .stakebtn2 {
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: transform .3s;
    margin-left: 3%;
    margin-right: 3%;
    font-weight: 700;
    color: #ffffff;
    background-color: #ce5d11;
    border-radius: 50px;
    border: none;
    box-shadow: #b15411 0px 5px;
  }

  .stakebtn:hover {
    transform: scale(1.1);
  }

  .stakebtn2:hover {
    transform: scale(1.1);
  }

  .stakebtnDiv {
    z-index: 10000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .logoPic {
    width: 110px;
    border-radius: 100%;
    border: 2px solid #ffffff;
  }

  .logoPic2 {
    border-radius: 50%;
    width: 10%;
    border: 1px solid #39a7f1;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 15px #1e98e9;
  }

  .logo {
    justify-content: flex-start;
    margin-top: auto;
    margin-bottom: auto;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;

  }

  .btnT {
    font-size: 30px;
    text-align: center;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 2%;
    margin-top: 5%;
  }

  * {
    box-sizing: border-box;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: #e89d38;
    padding-left: 100%;
    margin-top: 5%;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 18px;
    color: #ffffff;
    font-weight: 800;
  }

  .boxWrapC {
    margin-top: 10%;
  }

  .mainTB {
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 50px;

  }

  .mainTBP {
    text-align: center;
    color: white;
    font-weight: 500;
    font-size: 20px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  .sections3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 5%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #64a81f;
    border-radius: 50px;
    border: 2px white solid;
    padding: 10px;
  }

  .sT {
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 1%;
  }

  .sT2 {
    text-align: center;
    color: white;
    font-weight: 500;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .footer2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 10%;
  }

  .footer2 img {
    margin-left: 10px;
    margin-right: 10px;
    width: 40px;
    cursor: pointer;
    transition: transform .3s;
  }

  .footer2 img:hover {
    transform: scale(1.1);
  }

  .tName {
    color: white;
    justify-content: flex-start;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    font-size: 50px;
    font-weight: bold;
    cursor: pointer;
  }

  .stakeT {
    color: #facc15;
    font-weight: bold;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 45px;
  }

  .winnerSection {
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .winnerT {
    text-align: center;
    color: #facc15;
    font-weight: bold;
    font-size: 50px;
    text-decoration: underline white;
  }

  table {
    border-collapse: collapse;
    width: 80%;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: center;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #1f1f1f;
    color: #facc15;
  }

  .bg {
    background-color: #facc15;
    color: black;
  }



  .imageSet {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .imageSet img {
    width: 30%;
    transition: transform 1s;
  }

  .imageSet img:hover {
    width: 30%;
    transform: scale(1.2);
  }

  .logoNFT {
    width: 40%;
    transition: transform 1s;
  }

}

@media screen and (min-width: 1920px) {
  .back{  z-index: 100000;
    width: 50px;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;  
    cursor: pointer;
   }
  
  .blur{
    backdrop-filter: blur(5px);
    transform: translate(-50%, -50%);
    background-color: #00000094;
    position: fixed;
   width: 100%;
   height: 100vh;
   top: 50%;
   left: 50%;
   z-index: 100000;
   
   }
   
   .v2Quote{ text-align:center;
     color: white;
     font-weight: bold;
     border: 2px #facc15 solid;
     position: fixed;
     padding: 15px;
     font-size: 30px;
     top: 50%;
     left: 50%;
     z-index: 100000;
     transform: translate(-50%, -50%);
   
   }
  .stakeMainHome {
    display: flex;
    flex-flow: column nowrap;
    background-color: black;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    background: rgb(12,17,40);
    background: linear-gradient(99deg, rgba(12,17,40,1) 0%, rgba(0,0,0,1) 50%, rgba(10,33,23,1) 94%);
  }

  .homeBtn {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: space-between;
    width: 50%;
  }

  .homeBtn button {
    color: black;
    background-color: #facc15;
    width: 600px;
    padding: 20px;
    font-weight: bold;
    font-size: 30px;
    margin-left: 4%;
    margin-right: 4%;
  }

  .homeBtn button:hover{
    margin-bottom: 2%;
  }

  .homeImages {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-bottom: 6%;
  }

  .homeImages1 {
    animation: Slide_Down1 6s ease infinite;
  }

  .homeImages2 {
    animation: Slide_Down2 5s ease infinite;
  }

  @keyframes Slide_Down1 {
    0% {
      transform: translateY(-5%);
      opacity: 1;
    }

    50% {
      transform: translateY(20%);
    }

    100% {
      transform: translateY(-5%);
    }

  }

  @keyframes Slide_Down2 {
    0% {
      transform: translateY(-5%);
      opacity: 1;
    }

    50% {
      transform: translateY(20%);
    }

    100% {
      transform: translateY(-5%);
    }

  }


  .right {
    width: 700px;
    height: 602px;
    margin-top: -50%;
  }

  .homeLogo {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;

  }


  .stakeMain2 {
    border-right: 20px #f3ba2f solid;
    border-top: #f3ba2f 20px solid;

  }

  .sticky {
    width: 28%;

    bottom: 0;
    right: 90%;
    margin-top: -10%;
  }

  .logoNFT {
    width: 45%;
    transition: transform 1s;
  }

  .submitBtn {
    background-color: #44a06c;
    color: white;
    font-size: 20px;
    padding: 5px;
    border-radius: 5px;
  }

  .buyDetails2 {
    border: 3px black solid;
    width: 50%;
    padding: 10px;
    font-size: 20px;
  }

  .emailSectionH {
    color: black;
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 10px;
    color: #facc15;
  }

  .emailSection {
    color: #facc15;
    background: rgba(255, 255, 255, 0.096);
    border: 2px dashed #facc15;
    margin-bottom: 5%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .emailSection2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 20px;
    flex-flow: column;
  }

  .prizeBtn {
    background-color: #facc15;
    color: black;
    width: fit-content;
    border: none;
    width: 200px;
    box-shadow: 0px 2px #a58503;
  }

  .prizeBtnDis {
    background-color: #8f8767;
    color: rgb(61, 61, 61);
    width: fit-content;
    border: none;
    width: 200px;
    box-shadow: 0px 2px #978e6b;
    cursor: no-drop;

  }

  .step {
    color: white;
    font-size: 15px;
  }

  .connect2Mob {
    display: none;
  }

  .tableMobile {
    display: none;
  }

  .number {
    border-radius: 10px;
    border: 2px rgb(59, 131, 240) solid;
    padding: 10px;
    width: 300px;
    text-align: center;
  }

  .formBtn {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .adminBtns button {
    width: 300px;
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: transform .3s;
    font-weight: 700;
    color: #ffffff;
    background-color: #115dce00;
    border-radius: 10px;
    border: 2px rgb(59, 131, 240) solid;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
  }

  .sentOrders {
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
    background-color: #3b3b3b00;
    width: 60%;
    padding: 10px;

  }

  .view2 {
    margin-left: 5%;
    width: 20%;
  }

  .or {
    color: white;
    font-weight: 500;
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .view {
    margin-right: 5%;
  }

  .adminBtnsMain {
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-top: 5%;
    border-radius: 5px;
    padding: 20px;
    width: 50%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid #d79f60;
  }

  .orders {
    margin-top: 1%;
  }



  .t1 {
    color: white;
    font-size: 20px;
    text-align: center;
    margin-top: 5%;
    text-decoration: underline;
    font-weight: 500;
  }

  .t2 {
    color: white;
    font-size: 20px;
    text-align: center;
    margin-top: 5%;
    text-decoration: underline;
    font-weight: 500;
  }

  table {
    color: white;
    border-collapse: collapse;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 2%;
    margin-bottom: 5%;
  }

  td,
  th {
    border: 1px solid #dddddd;
    padding: 8px;
    text-align: center;

  }

  .hideTable {
    display: none;
  }

  .adminBtns {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 70%;
    margin-left: auto;
    margin-right: auto;

  }



  .adminBtns button:hover {
    transform: scale(1.1);
  }

  .buyDetails {
    color: white;
    font-size: 25px;
    text-align: left;
    margin-left: 3%;
    margin-top: 5%;
  }

  .btnsMainMob {
    display: none;
  }

  .stakeWC {
    width: 300px;
    background-color: #facc15;
    border: none;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-weight: 700;
    transition: transform .3s;
    font-size: 30px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;

  }

  .stakeWCMain {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .home {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    cursor: pointer;
    transition: transform .3s;
  }

  .home:hover {
    transform: scale(1.1);
  }

  .stakeWC:hover {
    transform: scale(1.1);
  }

  .navBarStake {
    justify-content: flex-end;
    padding: 20px;
    margin-bottom: 2%;
  }

  .navBarStake2 {
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    display: flex;
  }

  .btns {
    z-index: 10000;
    width: 150px;
    background-color: #facc15;
    color: rgb(0, 0, 0);
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    font-size: 20px;

  }

  .btnsDis {
    width: 150px;
    background-color: #77946c;
    border: none;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: transform .3s;
    cursor: no-drop;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .btns2 {
    width: 150px;
    color: rgb(0, 0, 0);
    padding: 5px;
    font-weight: 500;
    transition: transform .3s;
    background-color: rgb(238, 62, 62);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    border: none;
    font-size: 20px;
  }

  .btns2Dis {
    width: 150px;
    background-color: rgb(196, 134, 134);
    cursor: no-drop;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    transition: transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .btns:hover {
    transform: scale(1.1);
  }

  .btns2:hover {
    transform: scale(1.1);
  }

  .btnsMain {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    display: none;
  }

  .btnsMainBig {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .markBar {
    background-color: #000000;
    margin-top: 2%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    border: #facc15 3px solid;
    width: 90%;
    margin-left: auto;
    margin-right: auto;

    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
  }

  .markBarCon {
    background-color: #ffffff;
    display: flex;
    flex-flow: row nowrap;
    width: 20%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 10px;
  }

  .markBar2 {
    flex-flow: column nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: -10%;
  }

  .num {
    font-size: 26px;
    color: #000000;
    font-weight: bold;
    text-align: center;
  }

  .name {
    color: #000000;
    font-size: 23px;
    text-align: center;
    font-weight: 500;
  }

  .num2 {
    font-size: 23px;
    color: white;
    font-weight: bold;
    text-align: center;
  }

  .name2 {
    color: white;
    font-size: 20px;
    text-align: center;
    font-weight: 500;
  }

  .markBar img {
    width: 90px;
    height: 90px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 20%;
  }

  .markBarMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .stakeMain {
    display: flex;
    flex-flow: column nowrap;
    background-color: black;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .items {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .board {
    margin-top: 5%;
    margin-bottom: 5%;
    padding: 45px;
    border: #facc15 solid 5px;
    margin-left: 1%;
    margin-right: 1%;

    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .boardMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
  }

  .nameStake {
    width: 50%;
  }

  .carrots {
    width: 90px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .egg {
    width: 70px;
    height: 70px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .egg2 {
    width: 95px;
    height: 95px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .boardImg {
    background-color: #ffb963;

  }

  .boardPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-bottom: 8%;
  }

  .approveBtns {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 2%;
  }

  .approveBtns1 {
    background-color: #facc15;
    color: rgb(0, 0, 0);
    padding: 20px;
    font-size: 23px;
    border: none;
    font-weight: bold;
    width: 400px;
    transition: transform .3s;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .approveBtns1:hover {
    transform: scale(1.1);
  }

  .approveBtns2:hover {
    transform: scale(1.1);
  }

  .approveBtns3:hover {
    transform: scale(1.1);
  }

  .approveBtns3 {
    background-color: rgb(231, 80, 80);
    color: white;
    padding: 20px;
    font-size: 23px;
    border: none;
    font-weight: bold;
    width: 400px;
    transition: transform .3s;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .approveBtns3Dis {
    background-color: rgb(224, 111, 111);
    border: none;
    cursor: no-drop;
    color: rgb(187, 183, 183);

    padding: 20px;
    font-size: 23px;
    font-weight: bold;
    width: 400px;
    transition: transform .3s;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .approveBtns2 {
    background: none;
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #70512b solid;
    font-weight: bold;
    width: 200px;
    transition: transform .3s;
  }

  .rabbitImg {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
  }

  .rabbitImgLogo {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    border: #facc15 2px solid;
    margin-bottom: 2%;

  }

  .rabbitImg2 {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .itemsDiv {
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 5%;
    width: 40%;
  }

  .itemImg {
    width: 400px;
    height: 213px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg2 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg3 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg4 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemImg5 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .itemName {
    color: white;
    font-weight: 500;
    text-align: center;
    padding-top: 2%;
    font-size: 20px;
    background-color: #000000;
  }

  .buyBtn {
    background-color: #000000;
    padding-top: 2%;
    padding-bottom: 3%;

  }

  .buy {
    background-color: #d79f60;
    color: rgb(0, 0, 0);
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 50px;
    transition: transform .2s;
    border: white 2px solid;
    font-weight: 700;
    transition: ease 0.5s;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 5%;
  }

  .buy:hover {
    background-color: rgb(0, 0, 0);
    color: #d79f60;
  }

  @-webkit-keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
    }

    100% {
      -webkit-transform: scale(1.0);
      opacity: 0;
    }
  }

  @keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    100% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
      opacity: 0;
    }
  }

  .logo2 {
    width: 20%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 20px;
    font-weight: 600;
    background-color: #ffffff;
    color: #487a17;
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border-radius: 10px;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 18px;
    font-weight: 500;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 50px;
    font-weight: bold;
    color: white;
  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding: 2px;
    padding-bottom: 20px;
    margin-right: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 10%;
  }


  .rmPic img {
    width: 100%;
    background-size: cover;
  }

  .rmMain {
    margin-top: 10%;
  }

  .unstakeImgM {
    display: none;
  }

  .formStake {
    z-index: 1;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .allWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    background-color: #000000;
  }

  .light {
    width: 100%;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.8267682072829132) 0%, rgba(0, 0, 0, 0.4009978991596639) 23%, rgba(0, 0, 0, 0.3253676470588235) 52%, rgba(0, 0, 0, 0.19371498599439774) 73%), url('./assets/bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    background-attachment: fixed;
  }

  .light2 {
    width: 100%;
    background-color: #000000;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }


  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
    z-index: 1;
    font-family: "Third Rail - Demo";
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #ff9900;
  }

  .in2 {
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    z-index: 1;
    justify-content: center;
  }

  .parrot {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: #32743b7a -15px 15px;
    border-radius: 20px;
  }


  @keyframes glowing {
    0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  .intro2 {
    color: rgb(255, 255, 255);
    font-size: 18px;
    padding-bottom: 10px;
    text-align: center;
    z-index: 1;
    font-weight: 500;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 10px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;

  }

  .successfully {
    margin-top: 1%;
    font-size: 20px;
    color: #000000;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;

  }

  .discord {
    padding-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    transition: transform .2s;
  }

  .discord:hover {
    transform: scale(1.1);
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  ::-webkit-scrollbar-thumb {
    background: #67c4b6;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #8fc7bf;
  }


  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 3%;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    color: white;
    font-weight: 600;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div:hover {
    color: rgb(250, 125, 52);
    cursor: pointer;
    transition: ease 0.5s;
  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 50px;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .mintbuttondiv {
    margin-top: 20px;
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) -5px 5px;
    cursor: pointer;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) 5px 5px;
    cursor: pointer;

  }


  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    font-size: 20px;
    margin-right: 20px;
    margin-left: 20px;
    padding: 10px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    box-shadow: rgba(94, 94, 94, 0.288) 0px 5px;
  }


  .btnfos-0-3:hover {

    color: rgb(78, 78, 78);
    cursor: pointer;

  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }

  .nftamount {
    color: #44a06c;
    font-size: 70px;
  }

  .walletConnect {
    display: flex;
    justify-content: center;
    margin-top: 10%;
    z-index: 1;

  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: rgb(4, 121, 4);
  }


  .headers {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 15px;
    margin-left: auto;
    margin-right: auto;

    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
  }

  .wallet2 {
    background-color: #d79f60;
    color: rgb(0, 0, 0);
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 50px;
    transition: transform .2s;
    border: white 2px solid;
    font-weight: 700;
    transition: ease 0.5s;

  }

  .wallet2:hover {
    background-color: rgb(0, 0, 0);
    color: #d79f60;
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {
    z-index: 10000;
    background-color: black;
    border: solid 3px;
    color: rgb(255, 255, 255);
    padding: 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 270px;
    transition: transform .5s;
    z-index: 1;
    margin-bottom: 10%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    font-weight: bold;
  }

  .lookDown {
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  .wallet3::after {
    transition: all 0.8s
  }

  .totalSupply {
    text-align: center;
    font-size: 35px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    padding-bottom: 5%;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .footer {
    position: static;
    margin-bottom: 5%;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 20px;
    font-weight: 500;
  }


  .copyright2 {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 12px;
    padding-top: 6px;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .images_show {
    width: 90%;
  }

  .hide {
    display: none;
  }

  .stake {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: fit-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .stake2 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
  }

  .stake3 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
  }

  .stake:hover {}

  .hide:hover {}

  .tokenIds {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-bottom: 10%;
  }

  .tokenIds2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15%;
  }

  .tS {
    color: #3a9ae8;
    text-align: center;
    margin-top: 2%;
    font-size: 40px;
    font-family: 'Comic Kings', sans-serif;
  }

  .unstakeImg {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

  }

  .imagesAndBtn {
    justify-content: center;
  }

  .flip-card {
    background-color: transparent;
    width: 150px;
    perspective: 1000px;
    background-color: #e0706c;
    height: fit-content;

    margin-top: 10%;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .btnCenter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .flip-card-front {
    /* background-color: #bbb;*/
    color: black;
  }

  .flip-card-back {
    /*background-color: #2980b9;*/
    color: white;
    transform: rotateY(180deg);
  }

  .loginFormNote {
    height: 90%;
    width: 90%;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }

  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 80%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;
  }

  .formMain1 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;

    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
  }

  .ownNFT {
    margin-bottom: 12%;
  }

  .elementName {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
  }

  .elementName2 {
    font-weight: 500;
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .elementName3 {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .medalDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  .rewards {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .discordB {
    display: none;
  }

  .medal {
    width: 35px;
    height: 35px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .stakebtn {
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: transform .3s;
    margin-left: 3%;
    margin-right: 3%;
    font-weight: 700;
    color: #ffffff;
    background-color: #ce5d11;
    border-radius: 50px;
    border: none;
    box-shadow: #b15411 0px 5px;
  }

  .stakebtn2 {
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: transform .3s;
    margin-left: 3%;
    margin-right: 3%;
    font-weight: 700;
    color: #ffffff;
    background-color: #ce5d11;
    border-radius: 50px;
    border: none;
    box-shadow: #b15411 0px 5px;
  }

  .stakebtn:hover {
    transform: scale(1.1);
  }

  .stakebtn2:hover {
    transform: scale(1.1);
  }

  .stakebtnDiv {
    z-index: 10000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .logoPic {
    width: 110px;
    border-radius: 100%;
    border: 2px solid #ffffff;
  }

  .logoPic2 {
    border-radius: 50%;
    width: 10%;
    border: 1px solid #39a7f1;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 15px #1e98e9;
  }

  .logo {
    justify-content: flex-start;
    margin-top: auto;
    margin-bottom: auto;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;

  }

  .btnT {
    font-size: 30px;
    text-align: center;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 2%;
    margin-top: 5%;
  }

  * {
    box-sizing: border-box;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: #e89d38;
    padding-left: 100%;
    margin-top: 5%;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 18px;
    color: #ffffff;
    font-weight: 800;
  }

  .boxWrapC {
    margin-top: 10%;
  }

  .mainTB {
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 50px;

  }

  .mainTBP {
    text-align: center;
    color: white;
    font-weight: 500;
    font-size: 20px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  .sections3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 5%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #64a81f;
    border-radius: 50px;
    border: 2px white solid;
    padding: 10px;
  }

  .sT {
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 1%;
  }

  .sT2 {
    text-align: center;
    color: white;
    font-weight: 500;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .footer2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 10%;
  }

  .footer2 img {
    margin-left: 10px;
    margin-right: 10px;
    width: 40px;
    cursor: pointer;
    transition: transform .3s;
  }

  .footer2 img:hover {
    transform: scale(1.1);
  }

  .tName {
    color: white;
    justify-content: flex-start;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    font-size: 55px;
    font-weight: bold;
    cursor: pointer;
  }

  .stakeT {
    color: #facc15;
    font-weight: bold;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 45px;
  }

  .winnerSection {
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .winnerT {
    text-align: center;
    color: #facc15;
    font-weight: bold;
    font-size: 50px;
    text-decoration: underline white;
  }

  table {
    border-collapse: collapse;
    width: 70%;
    font-size: 25px;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: center;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #1f1f1f;
    color: #facc15;
  }

  .bg {
    background-color: #facc15;
    color: black;
  }



  .imageSet {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .imageSet img {
    width: 30%;
    transition: transform 1s;
  }

  .imageSet img:hover {
    width: 30%;
    transform: scale(1.2);
  }
  
}